<template>
    <generic-page>
        <back-to-dashboard />

        <div
            class="d-flex align-items-center justify-content-between mt-2 mb-1"
        >
            <h5>Jackpot</h5>

            <div>
                <jackpot-help />
            </div>
        </div>

        <div v-if="currentJackpot">
            <b-list-group>
                <b-list-group-item
                    class="d-flex align-items-center justify-content-between"
                >
                    <div>Jackpot Value</div>
                    <coin-badge :amount="currentJackpot.defaultMap.balance" />
                </b-list-group-item>
                <b-list-group-item
                    class="d-flex align-items-center justify-content-between"
                >
                    <div>Local Currency</div>
                    {{ currentJackpot.balanceLocal }}
                </b-list-group-item>
                <b-list-group-item>
                    <div
                        class="d-flex align-items-center justify-content-between"
                    >
                        <div>Days Remaining</div>
                        {{ currentJackpot.defaultMap.daysRemaining }}
                    </div>
                    <b-progress
                        height=".25rem"
                        class="mt-2"
                        variant="primary"
                        :value="
                            currentJackpot.defaultMap.percentageOfMonthPassed
                        "
                        max="100"
                    ></b-progress>
                </b-list-group-item>
            </b-list-group>

            <jackpot-leaderboard :jackpot="currentJackpot" />
        </div>
        <div v-else>
            <generic-loading />
        </div>
    </generic-page>
</template>

<script>
import { mapState } from "vuex";

import BackToDashboard from "@/components/utility/BackToDashboard";
import JackpotHelp from "@/components/jackpot/JackpotHelp";
import JackpotLeaderboard from "@/components/jackpot/JackpotLeaderboard";

export default {
    mounted() {
        this.$store.dispatch("jackpot/refresh");
    },
    computed: {
        ...mapState("jackpot", ["currentJackpot"]),
    },
    components: {
        BackToDashboard,
        JackpotHelp,
        JackpotLeaderboard,
    },
};
</script>