<template>
    <generic-page>
        <b-btn variant="link" size="lg" class="pl-0" @click="$router.back()">
            <font-awesome-icon size="lg" icon="fa-light fa-chevron-left" />
            Back
        </b-btn>

        <h5>Global Earning History</h5>

        <b-alert variant="primary" show class="small">
            <font-awesome-icon icon="fa-duotone fa-info-circle" />
            See how much others have earned on the platform, and how they're
            able to get rewards for the past 30 days
        </b-alert>

        <div class="d-flex align-items-center justify-content-between my-3">
            <div class="text-center">
                <div class="small">
                    {{ $t("Total Withdrawals") }}
                </div>
                <h3 class="text-primary mb-0">
                    {{ withdrawTotal }}
                </h3>
                {{ $t("Past 30 days") }}
            </div>
            <div class="text-center">
                <div class="small">
                    {{ $t("Total Invite Rewards") }}
                </div>
                <h3 class="text-primary mb-0">
                    {{ inviteTotal }}
                </h3>
                {{ $t("Past 30 days") }}
            </div>
        </div>

        <div v-if="loading">
            <generic-loading />
        </div>
        <div v-else>
            <div
                :key="inviteReward.id"
                v-for="inviteReward in inviteRewards"
                class="border p-2 rounded my-2"
            >
                <div class="d-flex align-items-center justify-content-between">
                    <h5 class="mb-0">
                        <div
                            v-if="inviteReward.type === 'invite'"
                            class="d-flex align-items-center"
                        >
                            <font-awesome-icon
                                class="mr-1"
                                icon="fa-duotone fa-chart-network"
                            />
                            {{ $t("Invite Reward") }}
                        </div>
                        <div
                            v-if="inviteReward.type === 'withdraw'"
                            class="d-flex align-items-center"
                        >
                            <font-awesome-icon
                                icon="fa-duotone fa-money-check-dollar-pen"
                                class="mr-1"
                            />
                            {{ $t("Withdrawal") }}
                        </div>
                    </h5>
                    <div class="text-primary lead font-weight-bold">
                        {{ inviteReward.local }}
                    </div>
                </div>
                <div v-if="inviteReward.rewardReason" class="my-2 text-center">
                    <div class="small">
                        {{ $t("Rewarded for:") }}
                    </div>
                    <img
                        v-if="inviteReward.invitedUserFlag"
                        :src="inviteReward.invitedUserFlag"
                        class="mr-1"
                    />
                    {{ inviteReward.rewardReason }}
                </div>
                <div
                    class="d-flex align-items-center justify-content-between mt-2"
                >
                    <div class="small text-secondary">
                        {{ inviteReward.createdRelative }}
                    </div>

                    <b-badge v-if="inviteReward.self" pill variant="primary">
                        {{ $t("Me") }}
                    </b-badge>
                    <b-badge
                        v-else
                        pill
                        variant="light"
                        class="d-flex align-items-center border"
                    >
                        <img :src="inviteReward.flagUrl" class="mr-1" />
                        {{ inviteReward.country }}

                        <code class="ml-3">
                            {{ inviteReward.codename }}
                        </code>
                    </b-badge>
                </div>
            </div>
        </div>
    </generic-page>
</template>

<script>
import { mapState } from "vuex";

export default {
    data: () => ({
        inviteRewards: null,
        inviteTotal: null,
        withdrawTotal: null,
        loading: false,
    }),
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    mounted() {
        this.loading = true;

        this.api.post("/creatorRewards/index").then((response) => {
            if (response?.data?.inviteRewards) {
                this.inviteRewards = response.data.inviteRewards;
                this.inviteTotal = response.data.inviteTotal;
                this.withdrawTotal = response.data.withdrawTotal;
            }

            this.loading = false;
        });
    },
    components: {},
};
</script>