import api from "@/service/api";

const state = () => ({
    refillCommissionPercentage: null,
    landingPages: null,
    currentBonusPercentage: null,
    copiedUrl: null,
    url: null,
    highValue: null,
    conversionExplainer: null,
})

const mutations = {
    setRefillCommissionPercentage(state, value) {
        state.refillCommissionPercentage = value
    },
    setLandingPages(state, value) {
        state.landingPages = value
    },
    setCurrentBonusPercentage(state, value) {
        state.currentBonusPercentage = value
    },
    setCopiedUrl(state, value) {
        state.copiedUrl = value
    },
    setUrl(state, value) {
        state.url = value
    },
    setHighValue(state, value) {
        state.highValue = value
    },
    setConversionExplainer(state, value) {
        state.conversionExplainer = value
    },
}

const actions = {
    refresh({ commit }) {
        api.post('/affiliate/dashboard/index')
            .then(response => {
                if (response?.data?.url) {
                    commit('setUrl', response.data.url);
                }

                if (response?.data?.refillCommissionPercentage) {
                    commit('setRefillCommissionPercentage', response.data.refillCommissionPercentage);
                }

                if (response?.data?.landingPages) {
                    commit('setLandingPages', response.data.landingPages);
                }

                if (response?.data?.currentBonusPercentage) {
                    commit('setCurrentBonusPercentage', response.data.currentBonusPercentage);
                }

                commit('setHighValue', response?.data?.highValue);
                commit('setConversionExplainer', response?.data?.conversionExplainer);
            })
    }
}


export default {
    namespaced: true,
    state,
    actions,
    mutations
}
