<template>
    <div>
        <b-list-group flush>
            <b-list-group-item class="setting-item" v-if="profile.story">
                <div class="label">About</div>
                <div class="value">
                    <preserve-whitespace :content="profile.story" />
                </div>
            </b-list-group-item>
            <b-list-group-item
                v-if="profile.looking && profile.looking.length"
                class="setting-item"
            >
                <div class="label">Looking for</div>
                <div class="value text-right">
                    <b-badge
                        variant="light"
                        class="mr-1"
                        pill
                        :key="looking.id"
                        v-for="looking in profile.looking"
                    >
                        {{ looking.answerTitle }}
                    </b-badge>
                </div>
            </b-list-group-item>
            <b-list-group-item class="setting-item">
                <div class="label">Age</div>
                <div class="value">{{ profile.age }}</div>
            </b-list-group-item>
            <b-list-group-item
                v-if="profile.stats && profile.stats.responsiveness"
                class="setting-item"
            >
                <div class="label">Response Rate</div>
                <div class="value">{{ profile.stats.responsiveness }}%</div>
            </b-list-group-item>
            <b-list-group-item class="setting-item">
                <div class="label">Location</div>
                <div class="value d-flex">
                    <img class="mr-1" :src="profile.flagUrl" />
                    {{ profile.country }}
                </div>
            </b-list-group-item>
            <b-list-group-item v-if="profile.language" class="setting-item">
                <div class="label">Language</div>
                <div class="value d-flex">
                    {{ profile.language.name }}
                </div>
            </b-list-group-item>
            <b-list-group-item v-if="profile.timezone" class="setting-item">
                <div class="label">Their Local Time</div>
                <div class="value">
                    <profile-local-time :profile="profile" />
                </div>
            </b-list-group-item>
            <b-list-group-item
                v-if="brand.enableLivestream && profile.lastLiveRelative"
                class="setting-item"
            >
                <div class="label">Last Livestream</div>
                <div class="value">
                    <div class="d-flex align-items-center">
                        {{ profile.lastLiveRelative }}
                    </div>
                </div>
            </b-list-group-item>
            <b-list-group-item class="setting-item">
                <div class="label">Last Active</div>
                <div class="value">
                    <div class="d-flex align-items-center">
                        <user-online-status
                            :standardMap="profile"
                            class="mr-1"
                        />
                        {{ profile.lastActiveRelative }}
                    </div>
                </div>
            </b-list-group-item>
            <profile-vetted />
        </b-list-group>
    </div>
</template>

<script>
import ProfileVetted from "@/components/profile/ProfileVetted";

import UserOnlineStatus from "@/components/user/UserOnlineStatus";
import ProfileLocalTime from "@/components/profile/ProfileLocalTime";
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("profile", ["profile"]),
        ...mapState("brand", ["brand"]),
    },
    components: {
        ProfileVetted,
        UserOnlineStatus,
        ProfileLocalTime,
    },
};
</script>