<template>
    <div v-if="currentOffer" class="text-primary">
        <b-modal
            v-model="modal"
            hide-footer
            hide-header
            centered
            scrollable
            body-class="bg-light rounded-lg"
            no-close-on-backdrop
        >
            <div v-if="currentOffer.offer === 'complete-profile'">
                <offer-complete-profile />
            </div>
            <div v-if="currentOffer.offer === 'profile-video'">
                <offer-profile-video />
            </div>
            <div v-if="currentOffer.offer === 'onesignal'">
                <offer-onesignal />
            </div>
            <div v-if="currentOffer.offer === 'creator-welcome'">
                <offer-creator-welcome />
            </div>
            <div v-if="currentOffer.offer === 'pay-to-chat-men'">
                <offer-pay-to-chat-men />
            </div>
            <div v-if="currentOffer.offer === 'updated-pricing-chat'">
                <offer-updated-pricing-chat />
            </div>
            <div v-if="currentOffer.offer === 'dont-get-scammed'">
                <offer-dont-get-scammed />
            </div>
            <div v-if="currentOffer.offer === 'creator-gallery'">
                <offer-creator-gallery />
            </div>
            <div v-if="currentOffer.offer === 'filipino4u-welcome'">
                <offer-filipino4u-welcome />
            </div>
            <div v-if="currentOffer.offer === 'emailComplaint'">
                <offer-email-complaint />
            </div>
            <div v-if="currentOffer.offer === 'primaryLanguage'">
                <offer-primary-language />
            </div>
        </b-modal>
    </div>
</template>

<script>
import OfferCompleteProfile from "@/components/offer/OfferCompleteProfile";
import OfferProfileVideo from "@/components/offer/OfferProfileVideo";
import OfferOnesignal from "@/components/offer/OfferOnesignal";
import OfferCreatorWelcome from "@/components/offer/OfferCreatorWelcome";
import OfferPayToChatMen from "@/components/offer/OfferPayToChatMen";
import OfferUpdatedPricingChat from "@/components/offer/OfferUpdatedPricingChat";
import OfferDontGetScammed from "@/components/offer/OfferDontGetScammed";
import OfferCreatorGallery from "@/components/offer/OfferCreatorGallery";
import OfferFilipino4uWelcome from "@/components/offer/OfferFilipino4uWelcome";
import OfferEmailComplaint from "@/components/offer/OfferEmailComplaint";
import OfferPrimaryLanguage from "@/components/offer/OfferPrimaryLanguage";

export default {
    computed: {
        modal: {
            get() {
                return this.$store.state.interface.displayOfferModalVisible;
            },
            set(value) {
                this.$store.commit(
                    "interface/setDisplayOfferModalVisible",
                    value
                );
            },
        },
        currentOffer() {
            return this.$store.state.displayOffer?.currentOffer;
        },
    },
    methods: {
        handleClick() {},
    },
    components: {
        OfferProfileVideo,
        OfferCompleteProfile,
        OfferPayToChatMen,
        OfferCreatorWelcome,
        OfferUpdatedPricingChat,
        OfferDontGetScammed,
        OfferEmailComplaint,
        OfferCreatorGallery,
        OfferOnesignal,
        OfferFilipino4uWelcome,
        OfferPrimaryLanguage,
    },
};
</script>
