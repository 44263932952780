<template>
    <generic-page>
        <back-to-dashboard />

        <h5>
            {{ $t("Links") }}
        </h5>

        <b-alert variant="primary" show class="small">
            <font-awesome-icon icon="fa-duotone fa-info-circle" />
            {{
                $t(
                    "You need to be logged out of your affiliate dashboard to view these links normally, open them in a different browser or use an Inconito/Private window to preview how they look to a regular user"
                )
            }}
        </b-alert>

        <div class="p-2 border">
            <div class="text-primary">
                {{ $t("Your unique tracking URLs for sharing") }}
            </div>
            <div class="small mt-2">
                {{
                    $t(
                        "The highest earning countries are USA, Canada, Australia and Europe, so we recommend focusing on inviting users from these countries. You can share with men in any country, if they end up as a paying customer you will earn regardless of what country they are in"
                    )
                }}
            </div>
            <affiliate-link-section
                :landingPages="getLandingPagesForTarget('male')"
            />
        </div>

        <div class="p-2 border mt-3">
            <div class="text-primary">
                {{ $t("Social/Chat share link") }}
            </div>
            <div class="small mt-2">
                {{
                    $t(
                        "Send users directly to the homepage of our website - good for sharing for mixed audiences, or directly sharing to your friends. Use this link when sharing over chat apps like WhatsApp, Messenger, Telegram, etc. This link will generate a preview with photo/description and direct them to the homepage. Use this to universally share it directly with your friends"
                    )
                }}
            </div>
            <affiliate-link-section
                :landingPages="getLandingPagesForTarget('social')"
            />
        </div>

        <b-alert variant="primary" show class="small">
            <font-awesome-icon icon="fa-duotone fa-info-circle" />
            {{
                $t(
                    "Do your best to share the links with the right audience, however, our system will automatically reward you regardless of which page they signup from. As long as they end up in our system after using your link, we'll be able to track the activities to your account and reward you whenever their is a purchase or withdrawal. We just provide these as examples of how best to target audiences with specific links"
                )
            }}
        </b-alert>
    </generic-page>
</template>

<script>
import { mapState } from "vuex";

import BackToDashboard from "@/components/utility/BackToDashboard";
import AffiliateLinkSection from "@/components/affiliate/links/AffiliateLinkSection";

export default {
    computed: {
        ...mapState("affiliate", [
            "refillCommissionPercentage",
            "landingPages",
        ]),
    },
    methods: {
        getLandingPagesForTarget(target) {
            return this.landingPages?.filter(
                (landingPage) => landingPage.target === target
            );
        },
    },
    components: {
        BackToDashboard,
        AffiliateLinkSection,
    },
};
</script>