<template>
    <div class="border-0 d-flex">
        <div
            :key="item.path"
            v-for="item in itemsForUser"
            class="text-center w-100"
        >
            <b-btn
                variant="light"
                @click="navigate(item)"
                class="border-0 bg-white rounded-0 position-relative"
                :class="{
                    'text-primary': isCurrentPath(item),
                }"
                block
            >
                <div>
                    <img
                        v-if="onboardingUser && item.replaceIconWithAvatar"
                        style="height: 20px"
                        class="rounded"
                        :class="{
                            'border border-primary': isCurrentPath(item),
                        }"
                        :src="onboardingUser.avatar"
                    />
                    <font-awesome-icon v-else :icon="item.icon" size="lg" />
                </div>

                <div class="small text-nowrap">
                    {{ item.label }}

                    <b-badge v-if="unreadCount(item)" variant="danger" pill>
                        {{ unreadCount(item) }}
                    </b-badge>
                </div>
            </b-btn>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
    data() {
        return {
            items: [
                {
                    key: "creator",
                    label: "Dashboard",
                    icon: "fa-duotone fa-home",
                    path: "/creator",
                    creator: true,
                    user: false,
                },
                {
                    key: "affiliate",
                    label: "Dashboard",
                    icon: "fa-duotone fa-home",
                    path: "/affiliate",
                    affiliate: true,
                    user: false,
                },
                {
                    key: "customer",
                    label: "Customers",
                    icon: "fa-duotone fa-hand-holding-dollar",
                    path: "/affiliate/customer/index",
                    affiliate: true,
                    user: false,
                },
                {
                    key: "affiliateCreator",
                    label: "Creators",
                    icon: "fa-duotone fa-clapperboard-play",
                    path: "/affiliate/creator/index",
                    affiliate: true,
                    user: false,
                },
                {
                    key: "browse",
                    label: "Search",
                    icon: "fa-duotone fa-user-magnifying-glass",
                    path: "/browse/search",
                    affiliate: false,
                    creator: true,
                    user: true,
                },
                {
                    key: "video",
                    label: "Videos",
                    icon: "fa-duotone fa-circle-play",
                    path: "/video",
                    affiliate: false,
                    creator: false,
                    user: true,
                    enabledForBrandKey: "enableVideo",
                },
                {
                    key: "swipe",
                    label: "Swipe",
                    icon: "fa-duotone fa-rectangle-vertical-history",
                    path: "/browse/swipe",
                    affiliate: false,
                    creator: true,
                    user: true,
                },
                {
                    key: "account",
                    label: "Account",
                    path: "/account",
                    icon: "fa-duotone fa-user",
                    replaceIconWithAvatar: true,
                    affiliate: false,
                    creator: true,
                    user: true,
                },
                {
                    key: "video",
                    label: "Videos",
                    icon: "fa-duotone fa-circle-play",
                    path: "/public/video/index",
                    public: true,
                    enabledForBrandKey: "enableVideo",
                },
                {
                    key: "login",
                    label: "Login",
                    path: "/login",
                    icon: "fa-duotone fa-user",
                    public: true,
                },
                // out of space
                // videos supplants these for now
                // {
                //     key: "feed",
                //     label: "Feed",
                //     icon: "fa-duotone fa-globe",
                //     path: "/feed",
                //     creator: false,
                //     affiliate: false,
                //     explicit: true,
                //     user: true,
                //     enabledForBrandKey: "enableFeed",
                // },
                // {
                //     key: "gallery",
                //     label: this.$t("Gallery"),
                //     icon: "fa-duotone fa-gallery-thumbnails",
                //     path: "/gallery",
                //     creator: false,
                //     affiliate: false,
                //     explicit: true,
                //     user: true,
                //     enabledForBrandKey: "enableGallery",
                // },
            ],
        };
    },
    computed: {
        ...mapState("brand", ["brand"]),
        ...mapState("pusher", ["liveUserIds"]),
        ...mapGetters("user", ["isLoggedIn"]),
        hasAccessedDashboard() {
            return this.onboardingUser?.creatorHasAccessedDashboard;
        },
        itemsForUser() {
            return this.items.filter((item) => {
                if (
                    item.enabledForBrandKey &&
                    !this.brand[item.enabledForBrandKey]
                ) {
                    return false;
                }

                if (
                    item.explicit === true &&
                    (this.onboardingUser?.censored ||
                        this.onboardingUser?.nextGeneration)
                ) {
                    return false;
                }

                // affiliate and creator are mutually exclusive under this scheme
                // and if they have both, affiliate will take precedence
                // as its more restrictive, by design since its T3+
                if (item.public && !this.isLoggedIn) {
                    return true;
                }

                if (this.onboardingUser?.affiliate) {
                    return item.affiliate;
                } else if (this.onboardingUser?.creator) {
                    return item.creator;
                } else if (this.isLoggedIn) {
                    return item.user;
                }
            });
        },
        primaryWidgets() {
            return this.availableWidgets.filter((widget) => widget.primary);
        },
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        unreadCount(item) {
            // custom logic to count each item
            // since they may have totally different sources
            if (item.key === "creator") {
                return this.onboardingUser?.creatorUnreadCount;
            }

            if (item.key === "live") {
                return this.liveUserIds.length;
            }
        },
        navigate(item) {
            this.$router.push(item.path);
        },
        isCurrentPath(item) {
            return this.$route?.meta?.primaryNavigation === item.key;
        },
    },
};
</script>
        
        