import api from "@/service/api";

const settings = [
    {
        slug: "stealth",
        name: "Stealth Mode"
    },
    {
        slug: "privacy",
        name: "Hide My Profile"
    },
    {
        slug: "blockedCountries",
        name: "Blocked Countries"
    },
    {
        slug: "blockedUsers",
        name: "Blocked Users"
    },
    {
        slug: "notification",
        name: "Notifications"
    },
    {
        slug: "sound",
        name: "Sounds"
    },
    {
        slug: "ignoreMyAge",
        name: "Ignore My Age"
    },
    {
        slug: "push",
        name: "Push Notifications"
    },
    {
        slug: "downloadApp",
        name: "Download the App"
    },
    {
        slug: "subscriptions",
        name: "Old Subscriptions"
    },
    {
        slug: "questions",
        name: "Questions"
    },
    {
        slug: "primaryLanguage",
        name: "Primary Language"
    },
    {
        slug: "logout",
        name: "Logout"
    },
];

const state = () => ({
    editOnboardingStep: null,
    editSetting: null,
})

const mutations = {
    setEditSetting(state, slug) {
        const setting = settings.find(setting => setting.slug === slug)

        if (!setting) {
            console.error(`Setting key not found [${slug}], missing from vuex store setting.js`)
            return
        }

        state.editSetting = setting
    },
    setEditOnboardingStep(state, step) {
        state.editOnboardingStep = step
    },
}

const actions = {
    editOnboardingStep({ commit, rootState }, step) {
        const onboardingStep = rootState.onboarding.steps.find(onboardingStepSearch => onboardingStepSearch.slug === step)

        if (!onboardingStep) {
            console.error(`Step not found [${step}], not found in onboarding steps`)
            return
        }

        commit('setEditOnboardingStep', onboardingStep)
        commit('interface/setSettingEditingOnboardingStep', true, { root: true })
        commit('onboarding/removeCurrentStep', null, { root: true })
    },
    editSetting({ commit }, step) {
        commit('setEditSetting', step)
        commit('interface/setSettingEditing', true, { root: true })
    },
    editPrivacy({ dispatch }, privacy) {
        api.post("/setting/private", {
            private: privacy
        }).then(() => {
            dispatch("onboarding/status", null, { root: true });
        });
    },
    close({ commit }) {
        commit('interface/setSettingEditing', false, { root: true });
    },
    closeOnboarding({ commit }) {
        commit("interface/setSettingEditingOnboardingStep", false, { root: true });
        commit("setEditOnboardingStep", null);
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
