<template>
    <generic-page>
        <div v-if="creator">
            <b-btn
                variant="link"
                size="lg"
                class="pl-0"
                to="/affiliate/creator/index"
            >
                <font-awesome-icon size="lg" icon="fa-light fa-chevron-left" />
                Creators
            </b-btn>

            <div class="d-flex align-items-center justify-content-between my-2">
                <div class="d-flex align-items-center">
                    <img
                        class="rounded mr-2"
                        :src="creator.standardMap.photo"
                    />
                    <h5 class="mb-0">
                        {{ creator.standardMap.name }}
                    </h5>
                </div>
                <div>
                    <coin-badge
                        class="bg-white"
                        :amount="creator.balance"
                        label="Current Balance"
                    />
                </div>
            </div>

            <b-list-group>
                <b-list-group-item
                    class="d-flex align-items-center justify-content-between"
                >
                    Last Active

                    <div class="d-flex align-items-center">
                        {{ creator.standardMap.lastActiveRelative }}
                    </div>
                </b-list-group-item>
                <b-list-group-item
                    class="d-flex align-items-center justify-content-between"
                >
                    Created
                    <div class="d-flex align-items-center">
                        {{ creator.standardMap.createdRelative }}
                    </div>
                </b-list-group-item>
                <b-list-group-item
                    class="d-flex align-items-center justify-content-between"
                >
                    Location
                    <div class="d-flex align-items-center">
                        <img class="mr-1" :src="creator.standardMap.flagUrl" />
                        {{ creator.standardMap.country }}
                    </div>
                </b-list-group-item>
                <b-list-group-item
                    class="d-flex align-items-center justify-content-between"
                    v-if="creator.creditRefillTotal"
                >
                    Total Spend
                    <div>${{ creator.creditRefillTotal }}</div>
                </b-list-group-item>
                <b-list-group-item
                    class="d-flex align-items-center justify-content-between"
                    v-if="creator.source"
                >
                    Source
                    <div>
                        {{ creator.source }}
                    </div>
                </b-list-group-item>
                <b-list-group-item
                    class="d-flex align-items-center justify-content-between"
                    v-if="creator.landing"
                >
                    Landing Page
                    <div>
                        {{ creator.landing }}
                    </div>
                </b-list-group-item>
                <b-list-group-item
                    class="d-flex align-items-center justify-content-between"
                    v-if="creator.daysActive"
                >
                    Days Active
                    <div>
                        {{ creator.daysActive }}
                    </div>
                </b-list-group-item>
                <b-list-group-item
                    class="d-flex align-items-center justify-content-between"
                    v-if="creator.activityCount"
                >
                    Activity Count
                    <div>
                        {{ creator.activityCount }}
                    </div>
                </b-list-group-item>
            </b-list-group>

            <h5 class="mt-3">Commission History</h5>
            <b-table-simple striped class="mb-0 mt-3">
                <thead>
                    <th>Date</th>
                    <th>Commission</th>
                </thead>
                <tbody>
                    <tr
                        :key="inviteReward.id"
                        v-for="inviteReward in creator.inviteRewards"
                    >
                        <td>
                            {{ inviteReward.createdRelative }}
                        </td>
                        <td>{{ inviteReward.commissionLocal }}</td>
                    </tr>
                    <tr>
                        <td class="font-weight-bold bg-white">Total</td>
                        <td class="font-weight-bold bg-white">
                            {{ creator.totalRewards }}
                        </td>
                    </tr>
                </tbody>
            </b-table-simple>
        </div>
        <div v-else>
            <generic-loading />
        </div>
    </generic-page>
</template>

<script>
export default {
    data() {
        return {
            creator: null,
        };
    },
    created() {
        if (this.$route.params?.id) {
            this.refresh();
        }
    },
    methods: {
        refresh() {
            this.api
                .post(`/affiliate/creator/${this.$route.params?.id}/get`)
                .then((response) => {
                    if (response?.data?.creator) {
                        this.creator = response.data.creator;
                    }
                });
        },
    },
    components: {},
};
</script>