<template>
    <div>
        <b-alert
            v-if="hasBlurredPhotos"
            variant="warning"
            show
            class="rounded-0"
        >
            <strong>
                <font-awesome-icon icon="fa-duotone fa-triangle-exclamation" />
                {{ $t("Show Unmoderated Photos?") }}
            </strong>
            <div class="small">
                {{
                    $t(
                        "Photo messages are not reviewed and may contain innapropriate content. Photos will remain blurred until you choose to reveal them in each conversation"
                    )
                }}
            </div>
            <b-btn
                @click="setShowPhotoMessage(1)"
                variant="primary"
                pill
                block
                class="mt-3"
            >
                {{ $t("Yes, show photos") }}
            </b-btn>
        </b-alert>
        <b-alert
            v-if="offerTranslation"
            variant="primary"
            show
            class="rounded-0"
        >
            <div class="small">
                {{ profile.name }}
                {{ $t("has indicated their primary language is") }}
                {{ profile.language.name }}.
                {{
                    $t(
                        "Do you want to enable automatic translation of messages? You can disable this later if you change your mind"
                    )
                }}
            </div>
            <b-btn-group class="w-100 mt-3">
                <b-btn
                    :disabled="saving"
                    @click="translationEnable"
                    variant="primary"
                    pill
                    class="mr-1"
                >
                    {{ $t("Enable") }}
                </b-btn>
                <b-btn
                    :disabled="saving"
                    @click="translationDisable"
                    variant="secondary"
                    pill
                    class="ml-1"
                >
                    {{ $t("Disable") }}
                </b-btn>
            </b-btn-group>
        </b-alert>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
    data() {
        return {
            saving: false,
        };
    },
    computed: {
        hasBlurredPhotos() {
            return (
                !this.getUserUserSetting(this.profile.id)?.showPhotoMessage &&
                this.messages?.filter(
                    (message) => !message.self && message.type === "photo"
                )?.length
            );
        },
        ...mapState("profile", ["profile"]),
        ...mapGetters("userUserSetting", ["getUserUserSetting"]),
        offerTranslation() {
            return (
                this.translationEligible &&
                !this.messages?.length &&
                !this.messagePreview
            );
        },
        ...mapState("profile", ["profile"]),
        ...mapState("conversation", [
            "messages",
            "translationEligible",
            "openConversationUserId",
            "messagePreview",
        ]),
    },
    methods: {
        setShowPhotoMessage(value) {
            this.$store.dispatch("userUserSetting/setShowPhotoMessage", {
                userId: this.profile.id,
                value,
            });
        },
        translationEnable() {
            this.saving = true;

            this.api
                .post(
                    `/messagePreview/${this.openConversationUserId}/translationEnable`
                )
                .then((response) => {
                    this.$store.commit(
                        "conversation/setMessagePreview",
                        response.data.messagePreview
                    );
                })
                .catch(() => {})
                .then(() => {
                    this.saving = false;
                });
        },
        translationDisable() {
            this.saving = true;

            this.api
                .post(
                    `/messagePreview/${this.openConversationUserId}/translationDisable`
                )
                .then((response) => {
                    this.$store.commit(
                        "conversation/setMessagePreview",
                        response.data.messagePreview
                    );
                })
                .catch(() => {})
                .then(() => {
                    this.saving = false;
                });
        },
    },
};
</script>