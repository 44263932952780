<template>
    <generic-page>
        <b-btn variant="link" size="lg" class="pl-0" @click="$router.back()">
            <font-awesome-icon size="lg" icon="fa-light fa-chevron-left" />
            Back
        </b-btn>

        <h5 class="mt-3">More Options</h5>

        <b-list-group flush>
            <b-list-group-item
                @click="$store.dispatch('setting/editSetting', 'logout')"
                button
                class="setting-item text-secondary"
            >
                {{ $t("Logout") }}
            </b-list-group-item>
            <b-list-group-item
                v-if="canDelete"
                to="/delete"
                class="setting-item text-danger"
            >
                {{ $t("Delete Account") }}
            </b-list-group-item>
        </b-list-group>

        <div v-if="onboardingUser.eligibleForChatSupport">
            <h5 class="mt-3">Need Help?</h5>
            <div class="p-3 border bg-white rounded">
                Visit our help &amp; support portal, get answers to any
                questions you have, contact support if you can't find what
                you're looking for
                <b-btn
                    to="/support"
                    button
                    variant="light"
                    block
                    pill
                    class="d-flex align-items-center mt-3"
                >
                    <div class="mr-2">
                        <font-awesome-icon
                            class="small-avatar branded"
                            icon="fa-duotone fa-circle-question"
                            size="lg"
                        />
                    </div>
                    Help &amp; Support
                </b-btn>
            </div>
        </div>
    </generic-page>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        canDelete() {
            if (this.onboardingUser.ghostMode) {
                return false;
            }

            if (this.onboardingUser.scheduledDelete) {
                return false;
            }

            return true;
        },
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    components: {},
};
</script>