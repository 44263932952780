<template>
    <div>
        <b-alert variant="danger" show>
            <h5>
                <font-awesome-icon icon="fa-duotone fa-triangle-exclamation" />
                {{ $t("Final Confirmation") }}
            </h5>

            <div>
                {{
                    $t(
                        "This is your last chance to back out, if you are unsure you can cancel"
                    )
                }}
            </div>

            <b-btn
                variant="secondary"
                @click="cancelDeletion"
                block
                pill
                class="mt-3"
            >
                {{ $t("I changed my mind, cancel") }}
            </b-btn>

            <b-btn
                class="mt-3"
                variant="danger"
                block
                pill
                @click="$store.dispatch('delete/confirm')"
            >
                {{ $t("Permanently Delete My Account") }}
            </b-btn>
        </b-alert>
    </div>
</template>

<script>
export default {
    methods: {
        cancelDeletion() {
            this.$store.commit("delete/reset");
            this.$router.push("/account");
        },
    },
};
</script>