<template>
    <b-alert v-if="conversionExplainer" show variant="warning">
        <h5>Account Converted</h5>
        <div>
            Your account has been converted to an affiliate account. You can
            still earn money, and withdraw your earnings by inviting customers
            or creators
        </div>

        <h5 class="mt-3">Customers</h5>
        <div>
            Every customer you invite who purchases credits will earn you a
            reward, the more customers you invite, the higher your reward
            commission will be, check the Payout Rates tab to see commission
            percentages
        </div>

        <h5 class="mt-3">Creators</h5>
        <div>
            Invite creators who will use the app and entertain customers by
            chatting, calling and posting content, when creators withdraw their
            earnings you will earn a percentage of each withdrawal
        </div>

        <b-btn @click="dissmisConverted" variant="primary" pill class="mt-3">
            Ok, got it
        </b-btn>
    </b-alert>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("affiliate", ["conversionExplainer"]),
    },
    methods: {
        dissmisConverted() {
            this.api
                .post("/affiliate/dashboard/dissmissConverted", {})
                .then(() => {
                    this.$store.dispatch("affiliate/refresh");
                });
        },
    },
};
</script>