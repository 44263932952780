<template>
    <b-dropdown
        v-if="messagePreview && translationEligible"
        size="sm"
        variant="light"
        toggle-class="bg-light rounded-pill border"
        dropup
        right
    >
        <template #button-content>
            {{ $t("Translation") }}

            <b-badge
                v-if="messagePreview.translation"
                variant="light"
                pill
                class="text-primary"
            >
                {{ $t("ON") }}
            </b-badge>
            <b-badge v-else variant="light" pill class="text-secondary">
                {{ $t("OFF") }}
            </b-badge>
        </template>

        <b-dropdown-item
            v-if="messagePreview.translation"
            @click="translationDisable"
        >
            {{ $t("Turn Translation OFF") }}
        </b-dropdown-item>
        <b-dropdown-item
            v-if="!messagePreview.translation"
            @click="translationEnable"
        >
            {{ $t("Turn Translation ON") }}
        </b-dropdown-item>
    </b-dropdown>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("conversation", [
            "openConversationUserId",
            "messagePreview",
            "translationEligible",
        ]),
    },
    methods: {
        translationEnable() {
            this.api
                .post(
                    `/messagePreview/${this.openConversationUserId}/translationEnable`
                )
                .then((response) => {
                    this.$store.commit(
                        "conversation/setMessagePreview",
                        response.data.messagePreview
                    );
                });
        },
        translationDisable() {
            this.api
                .post(
                    `/messagePreview/${this.openConversationUserId}/translationDisable`
                )
                .then((response) => {
                    this.$store.commit(
                        "conversation/setMessagePreview",
                        response.data.messagePreview
                    );
                });
        },
    },
};
</script>