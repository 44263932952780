<template>
    <div>
        <h5>
            {{ $t("Set Primary Language") }}
        </h5>

        <div>
            {{
                $t(
                    "Choose the language that you are most comfortable with. Users who speak a different language can have messages automatically translated"
                )
            }}
        </div>

        <div class="bg-white p-3 rounded border mt-3">
            <language-selector-index />
        </div>

        <div class="mt-3">
            <b-row class="mt-4">
                <b-col cols="12" md="6" class="my-2">
                    <b-btn
                        variant="primary"
                        @click="closeModal"
                        pill
                        block
                        size="lg"
                    >
                        Skip for now
                    </b-btn>
                </b-col>
                <b-col cols="12" md="6" class="my-2">
                    <b-btn
                        variant="secondary"
                        @click="maybeLater"
                        pill
                        block
                        size="lg"
                    >
                        Remind me later
                    </b-btn>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import LanguageSelectorIndex from "@/components/languageSelector/LanguageSelectorIndex";

export default {
    data() {
        return {
            unsubscribeToMutations: null,
        };
    },
    mounted() {
        this.unsubscribeToMutations = this.$store.subscribe((mutation) => {
            if (mutation.type === "languageSelector/setHasPrimaryLanguage") {
                this.closeModal();
                this.$store.dispatch("onboarding/status");
            }
        });
    },
    beforeDestroy() {
        if (this.unsubscribeToMutations) {
            this.unsubscribeToMutations();
        }
    },
    computed: {
        currentOffer() {
            return this.$store.state.displayOffer?.currentOffer;
        },
    },
    methods: {
        closeModal() {
            this.$store.commit("interface/setDisplayOfferModalVisible", false);
        },
        maybeLater() {
            this.api.post("/offer/removeViewedOffer", {
                offer: this.currentOffer.offer,
            });

            this.$store.commit("interface/setDisplayOfferModalVisible", false);
        },
    },
    components: {
        LanguageSelectorIndex,
    },
};
</script>