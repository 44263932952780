<template>
    <div>
        <div>Amount to withdraw</div>

        <coin-badge :amount="withdraw.balanceInt" />

        <div v-if="withdraw.jackpotAmount" class="mt-3">
            <div>Jackpot Contribution</div>

            <coin-badge :amount="withdraw.jackpotAmount" />
            ({{ withdraw.jackpotPercentage }}%)

            <div class="">
                <b-button
                    v-b-toggle.removeFees
                    variant="link"
                    class="p-0"
                    size="sm"
                >
                    <font-awesome-icon icon="fa-duotone fa-info-circle" />
                    How to remove jackpot contribution
                </b-button>
                <b-collapse id="removeFees" class="mt-2">
                    <b-alert variant="primary" show class="mt-2">
                        <h5>Invite 5 Customers or Creators</h5>
                        <div>
                            Every time you invite a new person and earn a
                            reward, your contribution will decrease by 1%
                        </div>
                        <div class="mt-3">
                            Once you have invited 5 people who earned rewards,
                            you will no longer be required to contribute to the
                            jackpot
                        </div>
                        <div class="mt-3">
                            We do not keep the jackpot contributions; they are
                            distributed to the top inviters every month as a
                            reward for their invites
                        </div>
                    </b-alert>
                </b-collapse>
            </div>
        </div>

        <b-alert variant="warning" show v-if="showWarning" class="small mt-2">
            <font-awesome-icon icon="fa-duotone fa-triangle-exclamation" />
            We recommend to wait until you have 500 credits for withdrawal to
            get the lowest transfer fee

            <div class="mt-2">
                <b-button
                    v-b-toggle.more
                    variant="link"
                    size="sm"
                    pill
                    class="px-0"
                >
                    How transfer fees work
                </b-button>
                <b-collapse id="more" class="my-3">
                    <b-table-simple striped small class="mt-2">
                        <thead>
                            <tr>
                                <th class="align-middle">Withdraw Amount</th>
                                <th class="align-middle">Estimated Fee</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="align-middle">
                                    <coin-badge :amount="100" />
                                </td>
                                <td class="align-middle">5%</td>
                            </tr>
                            <tr>
                                <td class="align-middle">
                                    <coin-badge :amount="200" />
                                </td>
                                <td class="align-middle">3%</td>
                            </tr>
                            <tr>
                                <td class="align-middle">
                                    <coin-badge :amount="500" />
                                </td>
                                <td class="align-middle">1%</td>
                            </tr>
                        </tbody>
                    </b-table-simple>

                    <div class="mt-3">
                        Our money transfer service charges a small fee depending
                        on the amount, so wait as long as you can to withdraw to
                        get the maximum possible transferred to your account
                    </div>
                </b-collapse>
            </div>
        </b-alert>

        <div class="mt-3">Select payout method</div>
        <b-btn
            @click="setType(type)"
            :key="type.key"
            v-for="type in types"
            block
            pill
            variant="light"
            class="d-flex align-items-center justify-content-between mt-2"
            size="lg"
        >
            <div>
                {{ type.name }}
            </div>
            <div>
                <font-awesome-icon size="lg" icon="fa-light fa-chevron-right" />
            </div>
        </b-btn>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        showWarning() {
            return this.withdraw?.balanceInt < 500;
        },
        ...mapState("withdraw", ["withdraw", "types"]),
    },
    methods: {
        setType(type) {
            this.$store.dispatch("withdraw/updateType", type);
        },
    },
    components: {},
};
</script>