import api from "@/service/api";

const state = () => ({
    requestedImmediate: null,
    selectedReason: null,
    scheduled: null,
    resolution: null,
    undeleteable: false,
    willingToWait: false,
    dontClaimContent: false,
    declinedAffiliate: false,
    eligibleForInstantDeletion: false,
})

const mutations = {
    setRequestedImmediate(state) {
        state.requestedImmediate = true
    },
    setSelectedReason(state, reason) {
        state.selectedReason = reason
    },
    setResolution(state, resolution) {
        state.resolution = resolution
    },
    setScheduled(state, scheduled) {
        state.scheduled = scheduled
    },
    setUndeleteable(state) {
        state.undeleteable = true
    },
    setWillingToWait(state) {
        state.willingToWait = true
    },
    setDontClaimContent(state) {
        state.dontClaimContent = true
    },
    setDeclinedAffiliate(state) {
        state.declinedAffiliate = true
    },
    setEligibleForInstantDeletion(state) {
        state.eligibleForInstantDeletion = true
    },
    reset(state) {
        state.selectedReason = null
        state.undeleteable = false
        state.willingToWait = false
        state.declinedAffiliate = false
        state.dontClaimContent = false
        state.resolution = null
        state.scheduled = null
        state.requestedImmediate = null
    },
}

const actions = {
    start({ commit, dispatch }) {
        commit('reset')
        dispatch('setting/editSetting', 'delete', { root: true })

        api
            .post("/delete/eligible")
            .then((response) => {
                if (response?.data?.eligibleForInstantDeletion) {
                    commit('setEligibleForInstantDeletion')
                }
            });
    },
    requestImmediate({ commit }) {
        api.post("/delete/requestImmediate").then(() => {
            commit('setRequestedImmediate')
        });
    },
    selectReason({ commit }, reason) {
        if (reason.resolution) {
            commit('setResolution', reason.resolution)
        }

        commit('setSelectedReason', reason)
    },
    changeReason({ commit }) {
        commit('reset')
    },
    saved({ state }) {
        const reasonId = state.selectedReason.reasonId
        api.post("/delete/save", { reasonId })
    },
    confirm({ state, commit, dispatch }) {
        api
            .post("/delete/confirm", {
                reasonId: state.selectedReason.reasonId,
            })
            .then((response) => {
                if (response?.data?.undeleteable) {
                    commit('setUndeleteable')
                }

                if (response?.data?.scheduled) {
                    commit('setScheduled', response.data.scheduled)
                    dispatch("onboarding/status", null, { root: true });
                }
            });
    },
    cancel({ commit, dispatch }) {
        commit('reset')

        api
            .post("/delete/cancel")
            .then(() => {
                dispatch("onboarding/status", null, { root: true });
            });
    },
    saveFailed({ commit }) {
        commit('setResolution', null)
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
