<template>
    <div class="p-2">
        <back-to-dashboard />

        <h3>
            {{ $t("Photos") }}
        </h3>
        <div>
            {{
                $t(
                    "Real user photos you can use in your promotional materials to help drive traffic to your invite links"
                )
            }}
        </div>

        <div :key="photo.id" v-for="photo in photos" class="border mt-3">
            <div
                class="bg-white p-3 d-flex align-items-center justify-content-between"
            >
                {{ photo.user.name }}

                <div>
                    <img :src="photo.user.flagUrl" />
                    {{ photo.user.location }}
                </div>
            </div>
            <div>
                <img class="img-fluid" :src="photo.defaultMap.medium" />
            </div>
            <div
                class="bg-white p-3 d-flex align-items-center justify-content-between"
            >
                {{ photo.defaultMap.createdRelative }}
                <a target="_blank" :href="photo.defaultMap.medium">Medium</a>
                <a target="_blank" :href="photo.defaultMap.large">Large</a>
            </div>
        </div>

        <div class="d-flex align-items-center justify-content-between p-3">
            <div>
                <b-btn
                    @click="previous"
                    variant="light"
                    size="lg"
                    block
                    pill
                    class="p-3 border-0 d-flex align-items-center"
                    :disabled="page === 0"
                >
                    <font-awesome-icon icon="fa-light fa-arrow-left" />
                    <div class="ml-2 d-none d-md-block">
                        {{ $t("Previous") }}
                    </div>
                </b-btn>
            </div>
            <div class="text-center text-secondary">Page {{ page }}</div>
            <div>
                <b-btn
                    @click="next"
                    variant="light"
                    size="lg"
                    block
                    pill
                    class="p-3 border-0 d-flex align-items-center"
                    :disabled="!probablyHasMoreSearchResults"
                >
                    <div class="mr-2 d-none d-md-block">
                        {{ $t("Next") }}
                    </div>
                    <font-awesome-icon icon="fa-light fa-arrow-right" />
                </b-btn>
            </div>
        </div>
    </div>
</template>



<script>
import BackToDashboard from "@/components/utility/BackToDashboard";

export default {
    data() {
        return {
            probablyHasMoreSearchResults: false,
            photos: null,
            page: 1,
        };
    },
    created() {
        this.refresh();
    },
    methods: {
        reset() {
            this.photos = null;
            this.$scrollToTop();
            this.probablyHasMoreSearchResults = false;
        },
        next() {
            this.reset();
            this.page++;
            this.refresh();
        },
        previous() {
            this.reset();
            this.page--;
            this.refresh();
        },
        refresh() {
            this.api
                .post("/affiliate/content/photos", {
                    page: this.page,
                })
                .then((response) => {
                    if (response?.data?.photos) {
                        this.photos = response.data.photos;
                        this.probablyHasMoreSearchResults =
                            response.data.hasMorePages;
                    }
                });
        },
    },
    components: {
        BackToDashboard,
    },
};
</script>