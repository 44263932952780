import api from "@/service/api";

const dateRangeDays = 30

const state = () => ({
    dashboard: null,
    creatorUser: null,
    dateRangeDays,
})

const mutations = {
    setDateRangeDays(state, value) {
        state.dateRangeDays = value;
    },
    setDashboard(state, source) {
        state.dashboard = source;
    },
    setCreatorUser(state, value) {
        state.creatorUser = value;
    },
}

const actions = {
    // pretty sure this is useless now, not really in use
    // but setting a random meta flag that is not really checked anywhere
    view({ dispatch }) {
        dispatch('refresh')

        setTimeout(() => {
            api.post("/setting/creatorHasAccessedDashboard").then(() => {
                dispatch("onboarding/status", null, { root: true });
            })
        }, 1)
    },
    refresh({ commit, state }) {
        api.post('/creator/dashboard', {
            dateRangeDays: state.dateRangeDays
        }).then((response) => {
            if (response?.data?.dashboard) {
                commit('setDashboard', response.data.dashboard)
            }

            if (response?.data?.creatorUser) {
                commit('setCreatorUser', response.data.creatorUser)
            }
        });
    },
}


const getters = {
    hasUserFlag: (state) => (flag) => {
        return state.userFlag?.includes(flag)
    },
}


export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
