<template>
    <generic-page>
        <affiliate-conversion-explainer
            v-if="conversionExplainer"
            class="mt-2"
        />

        <affiliate-share-url class="mt-2" />

        <div class="mt-2">
            <jackpot-summary v-if="highValue === false" />
            <affiliate-earnings-summary />
            <affiliate-payouts-summary />
            <affiliate-bonus-summary v-if="highValue" />
            <affiliate-links-summary v-if="highValue" />
            <affiliate-qrcode-summary />
            <affiliate-help-summary v-if="highValue === false" />
        </div>
    </generic-page>
</template>

<script>
import { mapState } from "vuex";

import JackpotSummary from "@/components/jackpot/JackpotSummary";

// legacy, need to update paths to use new dir structure
import AffiliateBonusSummary from "@/components/affiliate/summary/AffiliateBonusSummary";
import AffiliateEarningsSummary from "@/components/affiliate/summary/AffiliateEarningsSummary";
import AffiliateLinksSummary from "@/components/affiliate/summary/AffiliateLinksSummary";
import AffiliateQrcodeSummary from "@/components/affiliate/summary/AffiliateQrcodeSummary";
import AffiliateHelpSummary from "@/components/affiliate/summary/AffiliateHelpSummary";
import AffiliatePayoutsSummary from "@/components/affiliate/summary/AffiliatePayoutsSummary";

import AffiliateShareUrl from "@/components/affiliate/links/AffiliateShareUrl";
import AffiliateConversionExplainer from "@/components/affiliate/docs/AffiliateConversionExplainer";

export default {
    computed: {
        ...mapState("affiliate", ["highValue", "conversionExplainer"]),
    },
    components: {
        AffiliateEarningsSummary,
        AffiliateBonusSummary,
        AffiliateLinksSummary,
        AffiliateQrcodeSummary,
        AffiliateHelpSummary,
        AffiliatePayoutsSummary,
        AffiliateShareUrl,
        AffiliateConversionExplainer,
        JackpotSummary,
    },
};
</script>