<template>
    <div class="text-left">
        <b-alert variant="success" show>
            <font-awesome-icon
                icon="fa-light fa-check-circle"
                class="text-success"
            />
            You are eligible for account restoration

            <div class="mt-3">
                To restore your account, you must pay a compliance bond of 2000
                credits, which you can purchase for $100 USD in cryptocurrency
            </div>
            <div class="mt-3">
                Once paid, your account will be instantly restored
            </div>
        </b-alert>

        <b-card>
            <b-button
                v-b-toggle.creatorBondHowItWorks
                variant="secondary"
                block
                pill
            >
                How it works &amp; FAQ
            </b-button>
            <b-collapse id="creatorBondHowItWorks">
                <h5 class="mb-0 mt-3">Why do we require a bond?</h5>
                A bond is required because we found rule violations on your
                account that we couldn’t resolve. The bond ensures that if you
                break the rules in the future, you will have something to lose

                <h5 class="mb-0 mt-3">Why is it so expensive?</h5>
                We need to make sure creators on our platform follow the rules.
                Because of past non-compliance, this bond ensures you will stick
                to the rules. Any future rule violations can result in losing
                your bond

                <h5 class="mb-0 mt-3">Can I get my money back?</h5>
                Yes, this is not a fee. We are holding the bond to ensure
                compliance. If you leave the platform later and your account is
                in good standing, your bond will be fully returned to you

                <h5 class="mb-0 mt-3">
                    Why do I have to pay in cryptocurrency?
                </h5>
                Crypto payments are final and secure. We require payment in
                cryptocurrency because it guarantees we have real money, and can
                not be cancelled once transferred

                <how-crypto-works />
            </b-collapse>
        </b-card>

        <b-btn
            @click="creatorBond"
            variant="primary"
            block
            pill
            class="mt-3 py-3"
        >
            <h5 class="mb-0">Restore Account</h5>
            <div class="small">Pay $100 USD in crypto</div>
        </b-btn>
    </div>
</template>


<script>
import { mapState } from "vuex";

import HowCryptoWorks from "@/components/utility/HowCryptoWorks";

export default {
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        balance() {
            return this.onboardingUser?.wallet?.balance;
        },
        balanceLocal() {
            return this.onboardingUser?.wallet?.balanceLocal;
        },
    },
    methods: {
        creatorBond() {
            this.saving = true;

            this.api
                .post("/nowpayments/creatorBond")
                .then((response) => {
                    if (response?.data?.nowpaymentsInvoice?.invoiceUrl) {
                        document.location =
                            response?.data?.nowpaymentsInvoice?.invoiceUrl;
                    }
                })
                .catch(() => {})
                .then(() => {
                    this.saving = false;
                });
        },
    },
    components: {
        HowCryptoWorks,
    },
};
</script>