<template>
    <div class="p-2">
        <back-to-dashboard />

        <h3>
            {{ $t("Videos") }}
        </h3>
        <div>
            {{
                $t(
                    "Real user videos you can use in your promotional materials to help drive traffic to your invite links"
                )
            }}
        </div>

        <div :key="video.id" v-for="video in videos" class="border mt-3">
            <div
                class="bg-white p-3 d-flex align-items-center justify-content-between"
            >
                {{ video.user.name }}

                <div>
                    <img :src="video.user.flagUrl" />
                    {{ video.user.location }}
                </div>
            </div>
            <div>
                <video-player
                    height="500"
                    width="500"
                    class="img-fluid fake-link"
                    :muted="false"
                    :controls="true"
                    :autoplay="false"
                    :loop="false"
                    :mp4Url="video.defaultMap.mp4Url"
                    :webmUrl="video.defaultMap.webmUrl"
                />
            </div>
            <div
                class="bg-white p-3 d-flex align-items-center justify-content-between"
            >
                {{ video.defaultMap.createdRelative }}
                <a target="_blank" :href="video.download">Download</a>
            </div>
        </div>

        <div class="d-flex align-items-center justify-content-between p-3">
            <div>
                <b-btn
                    @click="previous"
                    variant="light"
                    size="lg"
                    block
                    pill
                    class="p-3 border-0 d-flex align-items-center"
                    :disabled="page === 0"
                >
                    <font-awesome-icon icon="fa-light fa-arrow-left" />
                    <div class="ml-2 d-none d-md-block">
                        {{ $t("Previous") }}
                    </div>
                </b-btn>
            </div>
            <div class="text-center text-secondary">Page {{ page }}</div>
            <div>
                <b-btn
                    @click="next"
                    variant="light"
                    size="lg"
                    block
                    pill
                    class="p-3 border-0 d-flex align-items-center"
                    :disabled="!probablyHasMoreSearchResults"
                >
                    <div class="mr-2 d-none d-md-block">
                        {{ $t("Next") }}
                    </div>
                    <font-awesome-icon icon="fa-light fa-arrow-right" />
                </b-btn>
            </div>
        </div>
    </div>
</template>



<script>
import BackToDashboard from "@/components/utility/BackToDashboard";
import VideoPlayer from "@/components/utility/VideoPlayer";

export default {
    data() {
        return {
            probablyHasMoreSearchResults: false,
            videos: null,
            page: 1,
        };
    },
    created() {
        this.refresh();
    },
    methods: {
        reset() {
            this.videos = null;
            this.$scrollToTop();
            this.probablyHasMoreSearchResults = false;
        },
        next() {
            this.reset();
            this.page++;
            this.refresh();
        },
        previous() {
            this.reset();
            this.page--;
            this.refresh();
        },
        refresh() {
            this.api
                .post("/affiliate/content/videos", {
                    page: this.page,
                })
                .then((response) => {
                    if (response?.data?.videos) {
                        this.videos = response.data.videos;
                        this.probablyHasMoreSearchResults =
                            response.data.hasMorePages;
                    }
                });
        },
    },
    components: {
        BackToDashboard,
        VideoPlayer,
    },
};
</script>