<template>
    <div>
        <h5 class="mt-3">{{ $t("Continue Earning?") }}</h5>
        <div>
            {{
                $t(
                    "Would you like to keep earning money on the platform? You can delete your profile and switch to an affiliate account, allowing you to earn by inviting others"
                )
            }}
        </div>
        <h5 class="mt-3">{{ $t("How It Works?") }}</h5>
        <div>
            {{
                $t(
                    "Once your profile is deleted, your photos and profile will no longer be visible. However, you can continue to earn money from the users you invite to the platform and withdraw your earnings"
                )
            }}
        </div>
        <div v-if="loading">
            <generic-loading />
        </div>
        <div v-else-if="confirm">
            <b-alert variant="warning" show>
                {{
                    $t(
                        "Your profile will be deleted, please confirm your selection. After deleting you will be redirected to our affiliate portal with more information"
                    )
                }}
            </b-alert>
            <b-btn
                @click="convertAffiliate"
                variant="primary"
                block
                pill
                size="lg"
                class="mt-3"
            >
                {{ $t("Delete profile, continue earning") }}
            </b-btn>
            <b-btn
                @click="confirm = false"
                variant="secondary"
                block
                pill
                size="sm"
                class="mt-3"
            >
                {{ $t("Cancel") }}
            </b-btn>
        </div>
        <div v-else>
            <b-btn
                @click="confirmAffiliate"
                variant="primary"
                block
                pill
                size="lg"
                class="mt-3"
            >
                {{ $t("Yes, continue earning") }}
            </b-btn>
            <b-btn
                @click="declineAffiliate"
                variant="secondary"
                block
                pill
                size="sm"
                class="mt-3"
            >
                {{ $t("No, I don't want to continue earning") }}
            </b-btn>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            loading: false,
            confirm: false,
        };
    },
    computed: {
        hasActiveSubscriptions() {
            return (
                this.$store.state.onboarding?.user?.subscribedUserIds?.length >
                0
            );
        },
        ...mapState("delete", ["selectedReason", "scheduled", "undeleteable"]),
        ...mapState("options", ["deleteReasons"]),
    },
    methods: {
        confirmAffiliate() {
            this.confirm = true;
        },
        declineAffiliate() {
            this.$store.commit("delete/setDeclinedAffiliate", true);
        },
        convertAffiliate() {
            this.loading = true;

            this.api
                .post("delete/convertAffiliate")
                .then(async () => {
                    // cleanup UI
                    this.$store.dispatch("activity/refresh");
                    this.$store.dispatch("message/refresh");

                    // wait on this
                    await this.$store.dispatch("onboarding/status");

                    // head to portal
                    this.$router.push("/");
                })
                .catch(() => {})
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>