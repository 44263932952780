<template>
    <div>
        <b-alert variant="primary" show class="small text-left">
            <font-awesome-icon icon="fa-duotone fa-info-circle" size="lg" />
            Select the language that you are most comfortable with. Messages can
            be translated automatically to the language you select
        </b-alert>
        <div v-if="!loading">
            <b-list-group>
                <b-list-group-item
                    action
                    @click="setPrimary(language.id)"
                    :key="language.id"
                    v-for="language in filteredLanguages"
                    class="text-left"
                >
                    <font-awesome-icon
                        v-if="isPrimary(language)"
                        class="text-primary"
                        icon="fa-solid fa-check-circle"
                    />
                    <font-awesome-icon
                        v-else
                        icon="fa-solid fa-circle"
                    ></font-awesome-icon>
                    <span class="ml-1">
                        {{ language.name }}
                    </span>
                </b-list-group-item>
            </b-list-group>

            <div class="mt-3">
                <b-btn
                    v-if="!showAll"
                    @click="showAll = true"
                    variant="light"
                    pill
                    block
                >
                    More Languages
                </b-btn>
                <b-btn
                    v-if="showAll"
                    @click="showAll = false"
                    variant="light"
                    pill
                    block
                >
                    Less Languages
                </b-btn>
            </div>
        </div>
        <div v-else>
            <generic-loading />
        </div>
    </div>
</template>


<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            loading: false,
            showAll: false,
            languages: null,
        };
    },
    created() {
        this.refresh();
    },
    computed: {
        filteredLanguages() {
            if (!this.showAll) {
                return this.languages?.filter((language) => language.major);
            }

            return this.languages;
        },
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        isPrimary(language) {
            return this.onboardingUser.primaryLanguage?.id === language.id;
        },
        setPrimary(id) {
            this.$store.dispatch("languageSelector/setPrimary", { id });
        },
        refresh() {
            this.loading = true;

            this.api
                .post("/language/get")
                .then((response) => {
                    this.languages = response.data.languages;
                })
                .catch(() => {})
                .then(() => {
                    this.loading = false;
                });
        },
    },
};
</script>