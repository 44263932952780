<template>
    <div class="mt-2">
        <h5 class="m-0">
            {{ $t("Can we try to solve your problem?") }}
        </h5>
        <div class="mt-3">
            {{
                $t(
                    "We're not perfect, but we try our best to help ensure everyone has the best possible experience. We'd like to try and help troubleshoot your issue if you can provide some details about the problem, screenshots are a big help."
                )
            }}
        </div>
        <div class="mt-3">
            {{
                $t(
                    "We'll take a look into your issue and see if we can go ahead and just solve it. If we need more information, we may send you a message in the app to follow up."
                )
            }}
        </div>

        <b-btn
            @click="savedDelete"
            variant="primary"
            block
            size="lg"
            pill
            class="mt-3"
        >
            {{ $t("Chat with Support") }}
        </b-btn>
        <b-btn
            @click="$store.dispatch('delete/saveFailed')"
            variant="secondary"
            size="sm"
            class="mt-3"
            pill
            block
        >
            {{ $t("No thanks, just delete") }}
        </b-btn>
    </div>
</template>

<script>
export default {
    methods: {
        savedDelete() {
            this.$router.push("/account");
            this.$store.dispatch("delete/saved");
            this.$store.dispatch("support/contact");
        },
    },
};
</script>