<template>
    <div>
        <b-modal
            v-model="onboardingModal"
            :title="editOnboardingStep && editOnboardingStep.name"
            hide-footer
            scrollable
            centered
            body-class="p-0"
        >
            <div v-if="editOnboardingStep">
                <onboarding-step :step="editOnboardingStep.slug" />
            </div>
        </b-modal>
        <b-modal
            v-model="settingModal"
            :title="editSetting && editSetting.name"
            hide-footer
            scrollable
            centered
            body-class="p-0"
        >
            <div v-if="editSetting">
                <div v-if="editSetting.slug === 'privacy'">
                    <setting-privacy />
                </div>
                <div v-if="editSetting.slug === 'blockedCountries'">
                    <setting-blocked-countries />
                </div>
                <div v-if="editSetting.slug === 'ignoreMyAge'">
                    <setting-ignore-my-age />
                </div>
                <div v-if="editSetting.slug === 'downloadApp'">
                    <setting-download-app />
                </div>
                <div v-if="editSetting.slug === 'blockedUsers'">
                    <setting-blocked-users />
                </div>
                <div v-if="editSetting.slug === 'stealth'">
                    <setting-stealth />
                </div>
                <div v-if="editSetting.slug === 'notification'">
                    <setting-notifications />
                </div>
                <div v-if="editSetting.slug === 'sound'">
                    <setting-sound />
                </div>
                <div v-if="editSetting.slug === 'subscriptions'">
                    <setting-subscriptions />
                </div>
                <div v-if="editSetting.slug === 'questions'">
                    <setting-questions />
                </div>
                <div v-if="editSetting.slug === 'logout'">
                    <setting-logout />
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { mapState } from "vuex";

import OnboardingStep from "@/components/onboarding/OnboardingStep";

import SettingPrivacy from "@/components/setting/SettingPrivacy";
import SettingBlockedCountries from "@/components/setting/SettingBlockedCountries";
import SettingIgnoreMyAge from "@/components/setting/SettingIgnoreMyAge";
import SettingDownloadApp from "@/components/setting/SettingDownloadApp";
import SettingBlockedUsers from "@/components/setting/SettingBlockedUsers";
import SettingNotifications from "@/components/setting/SettingNotifications";
import SettingSubscriptions from "@/components/setting/SettingSubscriptions";
import SettingQuestions from "@/components/setting/SettingQuestions";
import SettingSound from "@/components/setting/SettingSound";
import SettingStealth from "@/components/setting/SettingStealth";
import SettingLogout from "@/components/setting/SettingLogout";

export default {
    created() {},
    computed: {
        settingModal: {
            get() {
                return this.$store.state.interface.settingEditing;
            },
            set(value) {
                this.$store.commit("interface/setSettingEditing", value);
            },
        },
        onboardingModal: {
            get() {
                return this.$store.state.interface.settingEditingOnboardingStep;
            },
            set(value) {
                this.$store.commit(
                    "interface/setSettingEditingOnboardingStep",
                    value
                );
            },
        },
        ...mapState("setting", ["editOnboardingStep", "editSetting"]),
    },
    components: {
        OnboardingStep,
        SettingPrivacy,
        SettingBlockedCountries,
        SettingQuestions,
        SettingBlockedUsers,
        SettingIgnoreMyAge,
        SettingDownloadApp,
        SettingNotifications,
        SettingSubscriptions,
        SettingSound,
        SettingStealth,
        SettingLogout,
    },
};
</script>