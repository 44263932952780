<template>
    <generic-page>
        <div
            class="d-flex align-items-center justify-content-between mt-3 p-3 bg-white"
        >
            <h5 class="mb-0">Reserve</h5>
            <div class="small">
                <coin-badge :amount="reserveBalance" />
            </div>
        </div>

        <div class="mt-3">
            <h5 class="mt-3">How do I get my reserve?</h5>
            When a customer you invite buys credits, you automatically earn a
            reward. When you have credits in your reserve, we will match your
            earnings, and release that same amount from your reserve

            <h5 class="mt-3">Double earnings from reserve (2x):</h5>
            For example: if a customer buys credits and you earn 20 credits, we
            will double your reward, so you earn 40 instead of only 20. We will
            use your reserve to double your earnings until your reserve is empty
        </div>
    </generic-page>
</template>


<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("affiliate", ["reserveBalance"]),
    },
};
</script>