import api from "@/service/api";

const state = () => ({
    currentJackpot: null,
})

const mutations = {
    setCurrentJackpot(state, source) {
        state.currentJackpot = source;
    },
}

const actions = {
    refresh({ commit }) {
        api.post('/jackpot/index').then((response) => {
            commit('setCurrentJackpot', response?.data?.jackpot)
        });
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
