<template>
    <div class="mt-2">
        <h5 class="m-0">
            {{ $t("Did you know you can block entire countries?") }}
        </h5>
        <div class="mt-3">
            {{
                $t(
                    "When viewing a users profile, there are two block buttons at the bottom. One to block that individual user, and another to block the entire country"
                )
            }}
        </div>
        <div class="mt-3">
            {{
                $t(
                    "You can also block countries from your settings, and just pick which specific countries you want to meet people in"
                )
            }}
        </div>

        <b-btn
            @click="savedDelete"
            variant="primary"
            block
            size="lg"
            class="mt-3"
            pill
        >
            {{ $t("Manage Blocked Countries") }}
        </b-btn>
        <b-btn
            @click="$store.dispatch('delete/saveFailed')"
            variant="secondary"
            size="sm"
            class="mt-3"
            pill
            block
        >
            {{ $t("No thanks, just delete") }}
        </b-btn>
    </div>
</template>

<script>
export default {
    methods: {
        savedDelete() {
            this.$store.dispatch("delete/saved");
            this.$router.push("/account");
            this.$store.dispatch("setting/editSetting", "blockedCountries");
        },
    },
};
</script>