<template>
    <div>
        <h5>
            <span class="text-success">
                <font-awesome-icon
                    class="mr-1"
                    icon="fa-light fa-check-circle"
                />
            </span>
            {{ $t("Deletion Scheduled") }}
        </h5>
        <div class="mt-3">
            {{
                $t(
                    "Your account will be terminated from our system at this time:"
                )
            }}
            <div class="mt-3">
                {{ scheduledFormatted }}
            </div>
        </div>
        <div class="mt-3">
            <h5>
                {{ $t("No further action is required") }}
            </h5>
            {{
                $t(
                    "We'll automatically delete your account. For security purposes, account deletion is scheduled, and not immediate."
                )
            }}
            <div class="mt-2">
                <b-btn
                    class="text-secondary text-left p-0"
                    v-b-toggle.whyScheduled
                    variant="link"
                    size="sm"
                    block
                >
                    {{ $t("Why is it not immediate?") }}
                </b-btn>
            </div>
        </div>
        <div class="mt-4">
            <b-btn
                @click="$store.dispatch('user/logout')"
                variant="primary"
                size="lg"
                block
                pill
            >
                {{ $t("Logout Now") }}
            </b-btn>
        </div>

        <b-collapse id="whyScheduled">
            <h3 class="mt-3">
                {{ $t("Why is it scheduled?") }}
            </h3>
            <div>
                {{
                    $t(
                        "We don't know people's intentions when they join the app, giving people time to report any issues means that our platform stays more safe than others by giving people time to report any issues to us"
                    )
                }}
            </div>
            <div class="mt-3">
                {{
                    $t(
                        "Now that your account is scheduled for deletion, its no longer visible in the platform to other users except those you already interacted with, this allows for users to report people even after they have requested deletion"
                    )
                }}
            </div>

            <h5>
                {{ $t("I need it deleted now!") }}
            </h5>
            <p class="m-0">
                {{
                    $t(
                        "If you need to delete your account more urgently, you can press the link below and our moderation team will review your account, if there is no issue we will delete it as soon as we review it. We offer this on a case-by-case basis, and if your account has any suspicion of malicious activities, your request will be disregarded."
                    )
                }}
            </p>
            <div class="mt-2 mb-3">
                <div v-if="requestedImmediate">
                    {{
                        $t(
                            "We got your request, we will review it and email you shortly"
                        )
                    }}
                </div>
                <div v-else>
                    <b-button
                        variant="link"
                        class="text-secondary small p-0 m-0"
                        size="sm"
                        @click="$store.dispatch('delete/requestImmediate')"
                    >
                        {{ $t("Request Immediate Deletion ") }}
                    </b-button>
                </div>
            </div>
        </b-collapse>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("delete", ["scheduled", "requestedImmediate"]),
        scheduledFormatted() {
            if (this.scheduled) {
                return new Date(this.scheduled);
            }

            return null;
        },
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
};
</script>