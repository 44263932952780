<template>
    <div class="step w-100 p-3 text-center">
        <h3 v-if="currentStep">{{ currentStep.name }}</h3>
        <div v-if="step === 'terms'">
            <onboarding-terms />
        </div>
        <div v-if="step === 'dateOfBirth'">
            <onboarding-date-of-birth />
        </div>
        <div v-if="step === 'seeking'">
            <onboarding-seeking />
        </div>
        <div v-if="step === 'age'">
            <onboarding-age />
        </div>
        <div v-if="step === 'photo'">
            <onboarding-photos />
        </div>
        <div v-if="step === 'verification'">
            <onboarding-verification />
        </div>
        <div v-if="step === 'name'">
            <onboarding-name />
        </div>
        <div v-if="step === 'story'">
            <onboarding-story />
        </div>
        <div v-if="step === 'waitlist'">
            <onboarding-waitlist />
        </div>
        <div v-if="step === 'location'">
            <onboarding-location />
        </div>
        <div v-if="step === 'scope'">
            <onboarding-scope />
        </div>
        <div v-if="step === 'questions'">
            <onboarding-questions />
        </div>
        <div v-if="step === 'phone'">
            <onboarding-phone />
        </div>
        <div v-if="step === 'email'">
            <onboarding-email />
        </div>
        <div v-if="step === 'push'">
            <onboarding-push />
        </div>
        <div v-if="step === 'contact'">
            <onboarding-contact />
        </div>
        <div v-if="step === 'contactPrivacy'">
            <onboarding-contact-privacy />
        </div>
        <div v-if="step === 'city'">
            <onboarding-city />
        </div>
        <div v-if="step === 'membership'">
            <onboarding-membership />
        </div>
        <div v-if="step === 'credit'">
            <onboarding-credit />
        </div>
        <div v-if="step === 'privacy'">
            <onboarding-privacy />
        </div>
        <div v-if="step === 'howItWorks'">
            <onboarding-howItWorks />
        </div>
        <div v-if="step === 'invite'">
            <onboarding-invite />
        </div>
        <div v-if="step === 'invitesRequired'">
            <onboarding-invites-required />
        </div>
        <div v-if="step === 'anonymous'">
            <onboarding-anonymous />
        </div>
        <div v-if="step === 'cardVerification'">
            <onboarding-card-verification />
        </div>
        <div v-if="step === 'idVerification'">
            <onboarding-id-verification />
        </div>
        <div v-if="step === 'creatorBond'">
            <onboarding-creator-bond />
        </div>
        <div v-if="step === 'primaryLanguage'">
            <onboarding-primary-language />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import OnboardingDateOfBirth from "@/components/onboarding/OnboardingDateOfBirth";
import OnboardingSeeking from "@/components/onboarding/OnboardingSeeking";
import OnboardingAge from "@/components/onboarding/OnboardingAge";
import OnboardingPhotos from "@/components/onboarding/OnboardingPhotos";
import OnboardingVerification from "@/components/onboarding/OnboardingVerification";
import OnboardingName from "@/components/onboarding/OnboardingName";
import OnboardingStory from "@/components/onboarding/OnboardingStory";
import OnboardingWaitlist from "@/components/onboarding/OnboardingWaitlist";
import OnboardingLocation from "@/components/onboarding/OnboardingLocation";
import OnboardingQuestions from "@/components/onboarding/OnboardingQuestions";
import OnboardingScope from "@/components/onboarding/OnboardingScope";
import OnboardingTerms from "@/components/onboarding/OnboardingTerms";
import OnboardingPhone from "@/components/onboarding/OnboardingPhone";
import OnboardingEmail from "@/components/onboarding/OnboardingEmail";
import OnboardingPush from "@/components/onboarding/OnboardingPush";
import OnboardingContact from "@/components/onboarding/OnboardingContact";
import OnboardingMembership from "@/components/onboarding/OnboardingMembership";
import OnboardingContactPrivacy from "@/components/onboarding/OnboardingContactPrivacy";
import OnboardingCity from "@/components/onboarding/OnboardingCity";
import OnboardingAnonymous from "@/components/onboarding/OnboardingAnonymous";
import OnboardingCredit from "@/components/onboarding/OnboardingCredit";
import OnboardingPrivacy from "@/components/onboarding/OnboardingPrivacy";
import OnboardingHowItWorks from "@/components/onboarding/OnboardingHowItWorks";
import OnboardingInvite from "@/components/onboarding/OnboardingInvite";
import OnboardingInvitesRequired from "@/components/onboarding/OnboardingInvitesRequired";
import OnboardingCardVerification from "@/components/onboarding/OnboardingCardVerification";
import OnboardingIdVerification from "@/components/onboarding/OnboardingIdVerification";
import OnboardingCreatorBond from "@/components/onboarding/OnboardingCreatorBond";
import OnboardingPrimaryLanguage from "@/components/onboarding/OnboardingPrimaryLanguage";

export default {
    components: {
        OnboardingDateOfBirth,
        OnboardingSeeking,
        OnboardingAge,
        OnboardingPhotos,
        OnboardingName,
        OnboardingStory,
        OnboardingLocation,
        OnboardingWaitlist,
        OnboardingEmail,
        OnboardingScope,
        OnboardingMembership,
        OnboardingQuestions,
        OnboardingTerms,
        OnboardingPhone,
        OnboardingAnonymous,
        OnboardingVerification,
        OnboardingPush,
        OnboardingContact,
        OnboardingContactPrivacy,
        OnboardingCity,
        OnboardingCredit,
        OnboardingPrivacy,
        OnboardingInvite,
        OnboardingHowItWorks,
        OnboardingInvitesRequired,
        OnboardingCardVerification,
        OnboardingIdVerification,
        OnboardingCreatorBond,
        OnboardingPrimaryLanguage,
    },
    computed: {
        ...mapState("onboarding", ["currentStep"]),
    },
    props: ["step"],
};
</script>