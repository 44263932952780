<template>
    <div>
        <div v-if="showPhotoMessage || message.self">
            <img class="img-fluid" :src="message.content.photoLarge" />
        </div>
        <div v-else>
            <div class="overflow-hidden">
                <img
                    class="img-fluid message-photo-blur"
                    :src="message.content.photoLarge"
                />
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.message-photo-blur {
    filter: blur(16px);
}
</style>

<script>
import { mapGetters, mapState } from "vuex";

export default {
    computed: {
        showPhotoMessage() {
            return this.getUserUserSetting(this.profile.id)?.showPhotoMessage;
        },
        ...mapState("profile", ["profile"]),
        ...mapGetters("userUserSetting", ["getUserUserSetting"]),
    },
    props: ["message"],
};
</script>

