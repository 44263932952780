import api from "@/service/api";
import Vue from 'vue';

const state = () => ({
    userUserSetting: {},
})

const mutations = {
    // Vue 3 - no need to use this
    // setUserUserSetting(state, { key, value } = {}) {
    //     state.userUserSetting[key] = value
    // },
    // Vue 2 workaround, manually use .set so its reactive
    setUserUserSetting(state, { key, value } = {}) {
        Vue.set(state.userUserSetting, key, value)
    },
}

const getStorageKey = (rootState, userId) => {
    return `${rootState.onboarding.user.id}-${userId}`
}

const actions = {
    setShowPhotoMessage({ commit, rootState }, { userId, value }) {
        api.post(`/userUserSetting/${userId}/showPhotoMessage`, { value })
            .then((response) => {
                if (response?.data?.userUserSetting) {
                    commit('setUserUserSetting', {
                        key: getStorageKey(rootState, userId),
                        value: response?.data?.userUserSetting,
                    })
                }
            })
    },
    refresh({ commit, rootState }, userId) {
        if (!userId) {
            return;
        }

        api.post(`/userUserSetting/${userId}/get`).then((response) => {
            if (response?.data?.userUserSetting) {
                commit('setUserUserSetting', {
                    key: getStorageKey(rootState, userId),
                    value: response?.data?.userUserSetting,
                })
            }
        })
    },
}

const getters = {
    getUserUserSetting: (state, getters, rootState) => (userId) => {
        const key = getStorageKey(rootState, userId);
        return state.userUserSetting[key]
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
