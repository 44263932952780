<template>
    <div class="p-2">
        <back-to-dashboard />

        <div class="d-flex align-items-center justify-content-between">
            <h3 class="mb-0">Payouts</h3>
            <div>
                <b-badge variant="dark" pill class="border">
                    Commission Rate:
                    {{ refillCommissionPercentage }}%
                </b-badge>
            </div>
        </div>

        <b-table-simple striped class="mb-0 mt-3">
            <thead>
                <th>Credit Package</th>
                <th>Payout</th>
            </thead>
            <tbody>
                <tr :key="payout.price" v-for="payout in payouts">
                    <td class="align-middle">
                        {{ payout.price }}
                    </td>
                    <td class="align-middle text-primary">
                        {{ payout.payoutLocal }}
                    </td>
                </tr>
            </tbody>
        </b-table-simple>

        <b-alert variant="primary" show class="small mt-3">
            <font-awesome-icon icon="fa-duotone fa-info-circle" />
            Our service operates using a credit model. Users purchase credits
            which never expire, at the time of credit purchase we will
            automatically deposit the reward to your wallet that will be
            instantly available to you
            <div class="mt-3">
                The prices shown above are the different refill packages
                available to be purchased and how much you earn per each package
            </div>
        </b-alert>

        <div class="d-flex align-items-center justify-content-between">
            <h3 class="mb-0">Commission Tiers</h3>
            <div>
                <b-badge variant="dark" pill class="border">
                    New Buyer Count
                    {{ newBuyerCount }}
                </b-badge>
            </div>
        </div>

        <b-table-simple striped class="mb-0 mt-3">
            <thead>
                <th>Tier</th>
                <th>New Buyer Minimum</th>
                <th>Commission Rate</th>
            </thead>
            <tbody>
                <tr :key="tier" v-for="(info, tier) in tiers">
                    <th class="bg-white">Tier {{ tier }}</th>
                    <td class="align-middle text-primary">
                        {{ info.minimum }}
                    </td>
                    <td class="align-middle text-primary">
                        {{ info.commission }}%
                    </td>
                </tr>
                <tr></tr>
            </tbody>
        </b-table-simple>

        <b-alert variant="primary" show class="small mt-3">
            <font-awesome-icon icon="fa-duotone fa-info-circle" />
            New buyers are any invited user who purchases credits in the past 30
            days. Even if they delete their account, you're still credited.
            <div class="mt-3">
                The new buyer count is updated daily, and does not change in
                real-time upon purchase
            </div>
        </b-alert>
    </div>
</template>


<script>
import { mapState } from "vuex";

import BackToDashboard from "@/components/utility/BackToDashboard";

export default {
    data() {
        return {
            tiers: null,
            payouts: null,
            newBuyerCount: null,
        };
    },
    created() {
        this.api.post("/affiliate/dashboard/payouts").then((response) => {
            if (response?.data?.payouts) {
                this.tiers = response.data.tiers;
                this.payouts = response.data.payouts;
                this.newBuyerCount = response.data.newBuyerCount;
            }
        });
    },
    computed: {
        ...mapState("affiliate", ["refillCommissionPercentage"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    components: {
        BackToDashboard,
    },
};
</script>