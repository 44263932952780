<template>
    <div class="mt-2">
        <h5 class="m-0">
            {{ $t("Do you want to turn off notifciations instead?") }}
        </h5>
        <div class="mt-3">
            {{
                $t(
                    "You can completely disable notifications, or choose which ones you want to get in your settings"
                )
            }}
        </div>

        <b-btn
            @click="savedDelete"
            variant="primary"
            block
            size="lg"
            class="mt-3"
            pill
        >
            {{ $t("Turn off notifications") }}
        </b-btn>
        <b-btn
            @click="$store.dispatch('delete/saveFailed')"
            variant="secondary"
            block
            pill
            size="sm"
            class="mt-3"
        >
            {{ $t("No thanks, just delete") }}
        </b-btn>
    </div>
</template>

<script>
export default {
    methods: {
        savedDelete() {
            this.$store.dispatch("delete/saved");
            this.$router.push("/account");
            this.$store.dispatch("setting/editSetting", "notification");
        },
    },
};
</script>