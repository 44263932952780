<template>
    <generic-page>
        <div
            class="d-flex align-items-center justify-content-between mt-3 mb-2"
        >
            <h5 class="mb-0">Customers</h5>
            <div class="d-flex align-items-center">
                <b-dropdown
                    v-if="highValue"
                    text="Sort"
                    variant="light"
                    right
                    class="rounded-pill bg-white mr-2"
                >
                    <b-dropdown-item @click="setFilter('Earnings')">
                        Earnings
                    </b-dropdown-item>
                    <b-dropdown-item @click="setFilter('Newest')">
                        Newest
                    </b-dropdown-item>
                    <b-dropdown-item @click="setFilter('Days Active')">
                        Days Active
                    </b-dropdown-item>
                    <b-dropdown-item @click="setFilter('Activity Count')">
                        Activity Count
                    </b-dropdown-item>
                </b-dropdown>

                <affiliate-customer-create />
            </div>
        </div>

        <div v-if="users">
            <b-link
                style="all: unset"
                :to="{
                    name: 'affiliateCustomerView',
                    params: {
                        id: user.standardMap.id,
                    },
                }"
                :key="user.standardMap.id"
                v-for="user in users"
            >
                <user-standard-avatar
                    :timestamp="user.standardMap.created"
                    :standardMap="user.standardMap"
                    :nonPrimaryPhotos="false"
                    :hideBadges="true"
                    :userBetweenStatus="false"
                    size="small"
                    class="border-light border-top"
                >
                    <div v-if="user.creditRefillTotal" class="text-success">
                        <div v-if="highValue">
                            Spent ${{ user.creditRefillTotal }}
                        </div>
                        <div v-else>Purchased credits</div>
                    </div>
                </user-standard-avatar>
            </b-link>
        </div>
        <div v-else>
            <generic-loading />
        </div>

        <generic-pagination
            @next="next"
            @previous="previous"
            :hasMorePages="hasMorePages"
            :page="page"
        />
    </generic-page>
</template>

<script>
import { mapState } from "vuex";

import UserStandardAvatar from "@/components/user/UserStandardAvatar";
import AffiliateCustomerCreate from "@/components/affiliate/customer/AffiliateCustomerCreate";
import GenericPagination from "@/components/utility/GenericPagination";

export default {
    data() {
        return {
            users: false,
            hasMorePages: false,
            page: 1,
        };
    },
    created() {
        this.refresh();
    },
    computed: {
        ...mapState("affiliate", ["highValue"]),
    },
    methods: {
        setFilter(filter) {
            const query = {};

            if (filter) {
                query.filter = filter;
            }

            this.$router
                .push({
                    name: "affiliateCustomers",
                    query,
                })
                .then(() => {
                    this.refresh();
                });
        },
        refresh() {
            this.users = null;

            this.api
                .post("/affiliate/customer/index", {
                    page: this.page,
                    filter: this.$route.query?.filter,
                })
                .then((response) => {
                    if (response?.data?.users) {
                        this.users = response.data.users;
                    }

                    this.hasMorePages = response.data.hasMorePages;
                });
        },
        next() {
            this.page++;
            this.$scrollToTop();
            this.refresh();
        },
        previous() {
            this.page--;
            this.$scrollToTop();
            this.refresh();
        },
    },
    components: {
        UserStandardAvatar,
        AffiliateCustomerCreate,
        GenericPagination,
    },
};
</script>