<template>
    <div>
        <b-btn
            @click="$store.dispatch('delete/changeReason')"
            variant="link"
            class="px-0"
        >
            <font-awesome-icon size="lg" icon="fa-light fa-chevron-left" />
            {{ $t("Back") }}
        </b-btn>

        <delete-resolution-private v-if="resolution === 'private'" />
        <delete-resolution-support v-if="resolution === 'support'" />
        <delete-resolution-block v-if="resolution === 'block'" />
        <delete-resolution-notifications v-if="resolution === 'notification'" />
    </div>
</template>


<script>
import { mapState } from "vuex";
import DeleteResolutionPrivate from "@/components/delete/resolution/DeleteResolutionPrivate";
import DeleteResolutionNotifications from "@/components/delete/resolution/DeleteResolutionNotifications";
import DeleteResolutionSupport from "@/components/delete/resolution/DeleteResolutionSupport";
import DeleteResolutionBlock from "@/components/delete/resolution/DeleteResolutionBlock";

export default {
    computed: {
        ...mapState("delete", ["resolution"]),
    },
    components: {
        DeleteResolutionPrivate,
        DeleteResolutionNotifications,
        DeleteResolutionSupport,
        DeleteResolutionBlock,
    },
};
</script>