<template>
    <div class="p-2">
        <back-to-dashboard />

        <h5 class="mt-3">What does affiliate account mean?</h5>

        <div>
            An affiliate account allows you to earn money by inviting others to
            the platform. You can withdraw your earnings the same way as other
            users. Keep inviting users and your earnings will grow
        </div>

        <h5 class="mt-3">Can I lose access to my affiliate account?</h5>

        <div>
            No, affiliate accounts are never banned as long as you follow the
            rules. The only rules are: do not invite fake users and do not spam
            the link. Follow these simple rules, and you can earn money
            indefinitely
        </div>

        <h5 class="mt-3">What is considered a fake invite?</h5>

        <div>
            A fake invite is when you invite someone pretending to be a real
            customer who buys credits just to get you a reward. Invite people
            genuinely interested in the app who will engage with others and
            enjoy the platform
        </div>

        <h5 class="mt-3">What is considered SPAM?</h5>

        <div>
            SPAM is sharing the link in irrelevant places, like forums unrelated
            to social or dating apps. Share the link in dating groups, with
            friends, or people who might actually enjoy using the app. Always
            invite those who are interested in a social or dating platform
        </div>

        <h5 class="mt-3">
            I got an email that I got an invite reward, but no credits are in my
            wallet yet, why?
        </h5>

        <div>
            All new customers go through a screening process to prevent fake
            invites. This process ensures they are legitimate and may take some
            time. Invite genuinely interested users for a faster screening
            process
        </div>

        <h5 class="mt-3">Where can I find customers to invite?</h5>

        <div>
            You can meet potential customers on other social or dating apps.
            However, do not share your invite link directly on those apps, as
            they may ban you for SPAM. If you build a connection with someone on
            a dating app and later chat with them on WhatsApp, Telegram, or
            Messenger, you can share your link there. Always ensure the person
            would genuinely enjoy the platform and avoid spamming the link
        </div>

        <h5 class="mt-3">
            I tried to share my link with someone but they are having trouble,
            what other option is there?
        </h5>

        <div>
            Some chat apps have issues with links, especially when opened in the
            in-app browser, which can trigger security warnings. Unfortunately,
            this is a limitation of those apps. A simple solution is to ask for
            their email address. Then, click the (+Create) button at the top of
            the customer list and add them manually. If they don't see the
            email, remind them to check their spam or junk folder
        </div>

        <div
            v-if="onboardingUser.eligibleForChatSupport"
            class="bg-white p-3 border mt-3"
        >
            <h5>Need more help?</h5>
            <div>
                <b-button
                    v-b-toggle.chatSupport
                    variant="light"
                    class="border mt-3"
                    block
                    pill
                    size="sm"
                >
                    I have read all of the information above, but still have a
                    question
                </b-button>
                <b-collapse id="chatSupport">
                    <contact-support />
                </b-collapse>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.question {
    font-weight: bold;
    color: var(--primary);
}
.question:not(:first-of-type) {
    margin-top: 1rem;
}
</style>

<script>
import { mapState } from "vuex";

import BackToDashboard from "@/components/utility/BackToDashboard";
import ContactSupport from "@/components/utility/ContactSupport";

export default {
    computed: {
        ...mapState("brand", ["brand"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    components: {
        BackToDashboard,
        ContactSupport,
    },
};
</script>