<template>
    <div>
        <div v-if="messageError">
            <b-alert variant="danger" class="rounded-0 border-0 mb-0" show>
                <div class="d-flex align-items-center justify-content-between">
                    <div>{{ messageError }}</div>
                    <div>
                        <b-btn
                            @click="clearError"
                            variant="link"
                            class="p-0 text-secondary"
                        >
                            <font-awesome-icon
                                size="lg"
                                icon="fa-light fa-times"
                            />
                        </b-btn>
                    </div>
                </div>
            </b-alert>
        </div>
        <div>
            <b-input-group>
                <b-form-textarea
                    ref="focus"
                    @paste="onPaste"
                    @input="typing"
                    class="border-0 rounded-0 hide-scrollbars"
                    v-model="draft"
                    maxlength="255"
                    :placeholder="$t('Send a message...')"
                    rows="1"
                    max-rows="20"
                    tabindex="1"
                ></b-form-textarea>

                <template v-if="draft && draft.length" #append>
                    <b-button
                        :disabled="sending"
                        size="lg"
                        variant="primary"
                        class="px-3 rounded-0"
                        @click="send"
                        tabindex="2"
                    >
                        {{ $t("Send") }}
                    </b-button>
                </template>
            </b-input-group>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            sending: false,
            typingTimeout: null,
            alreadySentTypingThisSecond: false,
        };
    },
    created() {
        const savedDraft = localStorage.getItem(
            `messageDraft-${this.profile.id}`
        );
        if (savedDraft) {
            this.draft = savedDraft;
        }

        if (this.$screen.lg) {
            this.$nextTick(() => {
                this.$refs.focus.focus();
            });
        }
    },
    computed: {
        ...mapState("profile", ["profile"]),
        ...mapState("conversation", ["messageError"]),
        draft: {
            get() {
                return this.$store.state.conversation.draft;
            },
            set(value) {
                this.$store.commit("conversation/setDraft", value);

                // Save draft to localStorage whenever it changes
                if (value) {
                    localStorage.setItem(
                        `messageDraft-${this.profile.id}`,
                        value
                    );
                } else {
                    localStorage.removeItem(`messageDraft-${this.profile.id}`);
                }
            },
        },
    },
    beforeDestroy() {
        this.clearTimeoutIfExists();
    },
    methods: {
        onPaste() {
            this.api.post("/message/pasteDetected", {
                userId: this.profile.id,
            });
        },
        clearError() {
            this.$store.commit("conversation/setMessageError", null);
        },
        clearTimeoutIfExists() {
            if (this.typingTimeout) {
                clearTimeout(this.typingTimeout);
            }
        },
        typing() {
            if (this.alreadySentTypingThisSecond) {
                return;
            }

            this.alreadySentTypingThisSecond = true;
            this.clearTimeoutIfExists();

            this.typingTimeout = setTimeout(() => {
                this.alreadySentTypingThisSecond = false;
            }, 1000);

            this.api.post("/message/typing", {
                userId: this.profile.id,
            });
        },
        send() {
            this.sending = true;
            this.$refs.focus.focus();

            const instanceId = this.$uuid();
            const draftPriorToSending = this.draft;

            this.$store.commit("conversation/appendPending", {
                type: "text",
                content: draftPriorToSending,
                instanceId,
            });

            this.$store.commit(
                "message/appendLocallyMessagedUserId",
                this.profile.id
            );

            // Clearing draft also clears from localStorage
            this.draft = null;

            this.api
                .post("/message/send", {
                    messageText: draftPriorToSending,
                    userId: this.profile.id,
                    instanceId,
                })
                .catch((error) => {
                    if (
                        error?.response?.data?.code === "inviteUrlHasNoEffect"
                    ) {
                        this.$store.commit(
                            "conversation/setMessageError",
                            this.$t(
                                "Invite URLs cannot be shared here, please use your URL to invite new users only"
                            )
                        );

                        this.$store.commit(
                            "conversation/removePending",
                            instanceId
                        );
                        return;
                    }

                    this.$store.commit(
                        "conversation/setMessageError",
                        this.$t("Failed to send message")
                    );

                    this.$store.commit(
                        "conversation/removePending",
                        instanceId
                    );

                    // Restore the draft if sending failed
                    this.draft = draftPriorToSending;
                })
                .finally(() => {
                    this.sending = false;
                });
        },
    },
};
</script>
