<template>
    <div>
        <language-selector-index />
    </div>
</template>


<script>
import LanguageSelectorIndex from "@/components/languageSelector/LanguageSelectorIndex";

export default {
    data() {
        return {
            unsubscribeToMutations: null,
        };
    },
    mounted() {
        this.unsubscribeToMutations = this.$store.subscribe((mutation) => {
            if (mutation.type === "languageSelector/setHasPrimaryLanguage") {
                this.$store.dispatch("onboarding/status");
                this.$store.dispatch("setting/closeOnboarding");
            }
        });
    },
    beforeDestroy() {
        if (this.unsubscribeToMutations) {
            this.unsubscribeToMutations();
        }
    },
    components: {
        LanguageSelectorIndex,
    },
};
</script>
