<template>
    <div>
        <account-section-title>
            {{ $t("Privacy") }}
        </account-section-title>
        <b-list-group flush>
            <b-list-group-item
                v-if="canPrivate"
                @click="$store.dispatch('setting/editSetting', 'privacy')"
                button
                class="setting-item"
            >
                <div class="label">
                    {{ $t("Hide My Profile") }}
                </div>
                <div class="value">
                    <div v-if="onboardingUser.private">
                        {{ $t("On") }}
                    </div>
                    <div v-else>
                        {{ $t("Off") }}
                    </div>
                </div>
            </b-list-group-item>
            <b-list-group-item
                v-if="!onboardingUser.ghostMode"
                @click="$store.dispatch('setting/editSetting', 'notification')"
                button
                class="setting-item"
            >
                <div class="label">
                    {{ $t("Notifications") }}
                </div>
                <div class="value text-capitalize">
                    {{ onboardingUser.notifications }}
                </div>
            </b-list-group-item>
            <b-list-group-item
                @click="$store.dispatch('setting/editSetting', 'sound')"
                button
                class="setting-item"
            >
                <div class="label">Sounds</div>
                <div class="value">
                    <div v-if="onboardingUser.soundNewMessage">
                        {{ $t("On") }}
                    </div>
                    <div v-else>
                        {{ $t("Off") }}
                    </div>
                </div>
            </b-list-group-item>
        </b-list-group>
        <b-list-group-item
            v-if="canPrivate"
            @click="$store.dispatch('setting/editSetting', 'stealth')"
            button
            class="setting-item"
        >
            <div class="label">Stealth Mode</div>
            <div class="value">
                <div v-if="onboardingUser.stealthMode">On</div>
                <div v-else>Off</div>
            </div>
        </b-list-group-item>

        <account-section-title>
            {{ $t("Filters") }}
        </account-section-title>

        <b-list-group flush>
            <b-list-group-item
                v-if="!onboardingUser.ghostMode"
                @click="$store.dispatch('setting/editOnboardingStep', 'age')"
                button
                class="setting-item"
            >
                <div class="label">
                    {{ $t("Age Range") }}
                </div>
                <div class="value">
                    {{ onboardingUser.ageFrom }}
                    -
                    {{ onboardingUser.ageTo }}
                </div>
            </b-list-group-item>
            <b-list-group-item
                v-if="onboardingUser.countryScopeAvailable"
                @click="$store.dispatch('setting/editOnboardingStep', 'scope')"
                button
                class="setting-item"
            >
                <div class="label">
                    {{ $t("International") }}
                </div>
                <div class="value">
                    <div v-if="onboardingUser.countryScope">
                        {{ $t("My Country") }}
                    </div>
                    <div v-else>
                        {{ $t("Anywhere") }}
                    </div>
                </div>
            </b-list-group-item>
            <b-list-group-item
                v-if="!onboardingUser.ghostMode"
                @click="
                    $store.dispatch('setting/editSetting', 'blockedCountries')
                "
                button
                class="setting-item"
            >
                <div class="label">
                    {{ $t("Blocked Countries") }}
                </div>
                <div class="value">
                    {{ onboardingUser.blockedCountryCount }}
                </div>
            </b-list-group-item>
            <b-list-group-item
                @click="$store.dispatch('setting/editSetting', 'blockedUsers')"
                button
                class="setting-item"
            >
                <div class="label">Blocked Users</div>
                <div class="value">{{ onboardingUser.blockedUserCount }}</div>
            </b-list-group-item>
        </b-list-group>

        <div class="py-4 text-center">
            <b-btn
                to="/account/more"
                variant="light"
                pill
                class="text-secondary"
            >
                More Options
                <font-awesome-icon icon="fa-light fa-chevron-right" />
            </b-btn>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import AccountSectionTitle from "@/components/account/AccountSectionTitle";

export default {
    computed: {
        canPrivate() {
            if (this.onboardingUser.ghostMode) {
                return false;
            }

            if (this.onboardingUser.privateAllowed) {
                return true;
            }

            return false;
        },
        hasIgnoreMyAge() {
            return this.onboardingUser && this.onboardingUser.ignoreMyAge;
        },
        ...mapState("system", ["cordova"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    components: {
        AccountSectionTitle,
    },
};
</script>