<template>
    <div>
        <div class="d-flex align-items-center justify-content-between my-2">
            <div class="d-flex align-items-center">
                <img class="rounded mr-2" :src="customer.standardMap.photo" />
                <h5 class="mb-0">
                    {{ customer.standardMap.name }}
                </h5>
            </div>
        </div>

        <b-list-group>
            <b-list-group-item
                class="d-flex align-items-center justify-content-between"
            >
                Created
                <div class="d-flex align-items-center">
                    {{ customer.standardMap.createdRelative }}
                </div>
            </b-list-group-item>
            <b-list-group-item
                class="d-flex align-items-center justify-content-between"
            >
                Location
                <div class="d-flex align-items-center">
                    <img class="mr-1" :src="customer.standardMap.flagUrl" />
                    {{ customer.standardMap.country }}
                </div>
            </b-list-group-item>
        </b-list-group>
    </div>
</template>

<script>
export default {
    props: ["customer"],
};
</script>