<template>
    <div id="message-list" class="flex-grow-1 p-3 hide-scrollbars">
        <div v-if="loadingMessages" class="text-center p-5 text-secondary">
            <div class="my-2">
                <b-spinner variant="dark" />
            </div>
        </div>

        <div class="message self" :key="message.id" v-for="message in pending">
            <div>
                <div class="pending d-flex align-items-top">
                    <message-content :message="message" />
                    <div v-if="onboardingUser && message.self">
                        <img
                            class="rounded-circle"
                            :src="onboardingUser.avatar"
                        />
                    </div>
                </div>
                <div v-if="message.error">
                    <b-alert variant="danger" show class="mb-0">
                        <div>
                            <font-awesome-icon
                                icon="fa-duotone fa-triangle-exclamation"
                            />
                            {{ message.error }}
                        </div>
                    </b-alert>

                    <div class="p-2">
                        <b-btn
                            @click="removePending(message)"
                            variant="light"
                            pill
                            block
                            size="lg"
                            class="text-danger"
                        >
                            <font-awesome-icon icon="fa-light fa-times" />
                            Remove
                        </b-btn>
                    </div>
                </div>
            </div>
        </div>

        <div
            @click="detail(message)"
            class="message fake-link"
            :class="{
                self: message.self,
            }"
            :key="message.id"
            v-for="message in messages"
        >
            <div class="d-flex align-items-top">
                <div v-if="profile && !message.self">
                    <img class="rounded-circle" :src="profile.photo" />
                </div>
                <message-content :message="message" />
                <div v-if="onboardingUser && message.self">
                    <img class="rounded-circle" :src="onboardingUser.avatar" />
                </div>
            </div>
        </div>

        <b-btn
            v-if="conversationHasMoreMessages"
            @click="$store.dispatch('conversation/more')"
            block
            class="btn-brand py-3"
            variant="light"
            size="lg"
            pill
        >
            Load More
        </b-btn>
        <b-modal
            v-model="modal"
            id="modal-message-detail"
            title="Message"
            hide-footer
        >
            <div v-if="message">
                <message-content :message="message" />

                <div v-if="message.translation" class="p-3 bg-light">
                    <div class="small">
                        {{ $t("Original Message") }}
                    </div>
                    {{ message.content }}
                </div>

                <div v-if="message.type === 'photo'">
                    <b-btn
                        @click="fullSize"
                        variant="link"
                        class="text-left"
                        block
                    >
                        View Full Size
                    </b-btn>
                </div>

                <b-alert v-if="message.refundable" show variant="primary">
                    User did not reply within reward period, so message is
                    refundable

                    <div v-if="refunded" class="mt-3 text-success">
                        <font-awesome-icon
                            class="mr-1"
                            icon="fa-light fa-check"
                        />
                        Credits have been refunded to your wallet
                    </div>
                    <div v-else>
                        <b-btn
                            variant="primary"
                            pill
                            block
                            @click="refundMessage"
                            class="mt-3"
                        >
                            Refund Credits
                        </b-btn>
                    </div>
                </b-alert>

                <div v-if="message.type === 'text'">
                    <b-btn
                        variant="link"
                        class="text-left"
                        block
                        @click="copyText"
                    >
                        <div v-if="copied">
                            <font-awesome-icon
                                class="mr-1"
                                icon="fa-light fa-check"
                            />
                            Copied
                        </div>
                        <div v-else>Copy text</div>
                    </b-btn>
                </div>

                <div class="mt-2">
                    <b-btn
                        variant="link"
                        class="text-danger text-left"
                        block
                        @click="deleteMessage(false)"
                    >
                        Delete Message
                    </b-btn>
                    <b-btn
                        variant="link"
                        class="text-danger text-left"
                        block
                        @click="deleteMessage(true)"
                    >
                        Delete Message for All
                    </b-btn>
                </div>
            </div>
        </b-modal>
        <b-modal
            hide-header
            hide-footer
            v-model="photoModal"
            body-class="p-0"
            modal-class="modal-full-height"
            no-close-on-esc
        >
            <div>
                <div class="d-flex justify-content-between floating-menu-bar">
                    <div></div>

                    <b-btn
                        @click="
                            $store.commit(
                                'interface/setConversationFullSizeGalleryVisible',
                                false
                            )
                        "
                        variant="light"
                        class="action-button glass border-0"
                    >
                        <font-awesome-icon size="lg" icon="fa-light fa-times" />
                    </b-btn>
                </div>
                <div class="swiper swiper-fullsize" ref="swiperFullSize">
                    <div class="swiper-wrapper">
                        <div
                            class="swiper-slide"
                            :key="photo.id"
                            v-for="photo in photoMessages"
                        >
                            <div class="swiper-zoom-container">
                                <img :src="photo.content.photoLarge" />
                            </div>
                        </div>
                    </div>
                    <div class="swiper-pagination"></div>
                    <div class="swiper-button-prev"></div>
                    <div class="swiper-button-next"></div>
                    <div class="swiper-scrollbar"></div>
                </div>
            </div>
        </b-modal>
    </div>
</template>


<style lang="scss" scoped>
#message-list {
    overflow-y: scroll;
    display: flex;
    flex-direction: column-reverse;
}
.message {
    display: inline-block;
    max-width: 85%;
    border-radius: 1.5rem;
    margin-top: 0.3rem;
    word-break: break-word;
    background-color: var(--primary);

    &.self {
        margin-left: auto;
        background-color: var(--white);
    }

    .pending {
        opacity: 0.5;
    }

    &:not(.self) {
        color: #fff;
        margin-right: auto;
    }
}
</style>

<script>
import { mapState } from "vuex";
import MessageContent from "@/components/conversation/MessageContent";
import copy from "clipboard-copy";

import Swiper, { Navigation, Pagination, Keyboard, Zoom } from "swiper";

import "swiper/swiper-bundle.css";

Swiper.use([Navigation, Pagination, Keyboard, Zoom]);

export default {
    data() {
        return {
            message: null,
            modal: false,
            copied: false,
            refunded: false,
        };
    },
    computed: {
        photoModal: {
            get() {
                return this.$store.state.interface
                    .conversationFullSizeGalleryVisible;
            },
            set(value) {
                this.$store.commit(
                    "interface/setConversationFullSizeGalleryVisible",
                    value
                );
            },
        },
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        ...mapState("profile", ["profile"]),
        ...mapState("conversation", [
            "messages",
            "pending",
            "conversationHasMoreMessages",
            "loadingMessages",
        ]),
        photoMessages() {
            if (!this.messages) {
                return [];
            }

            return this.messages.filter((message) => message.type === "photo");
        },
    },
    methods: {
        removePending(message) {
            this.$store.commit(
                "conversation/removePending",
                message.instanceId
            );
        },
        fullSize() {
            this.modal = false;

            this.$store.commit(
                "interface/setConversationFullSizeGalleryVisible",
                true
            );

            this.$nextTick(() => {
                this.swiperFullSize = new Swiper(this.$refs.swiperFullSize, {
                    keyboard: true,
                    zoom: true,
                    pagination: {
                        el: ".swiper-pagination",
                    },
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                    },
                    scrollbar: {
                        el: ".swiper-scrollbar",
                    },
                });
            });
        },
        deleteMessage(deleteForAll) {
            this.$store.commit("conversation/deleteMessage", this.message);

            this.api
                .post("/message/delete", {
                    messageId: this.message.id,
                    deleteForAll,
                })
                .then(() => {
                    this.message = null;
                    this.modal = false;
                })
                .catch(() => {});
        },
        detail(message) {
            this.message = message;
            this.modal = true;
            this.refunded = false;
        },
        refundMessage() {
            this.api
                .post("/message/refund", {
                    id: this.message.id,
                })
                .then(() => {
                    this.refunded = true;

                    this.$store.dispatch("conversation/load", {
                        userId: this.profile.id,
                    });
                });
        },
        deleteConversation() {
            this.api
                .post("/message/deleteConversation", {
                    userId: this.profile.id,
                })
                .then(() => {
                    this.$store.commit(
                        "message/deleteConversation",
                        this.profile.id
                    );

                    this.$store.dispatch("profile/close");
                })
                .catch(() => {});
        },
        copyText() {
            copy(this.message.content);

            this.copied = true;

            setTimeout(() => {
                this.copied = false;
            }, 3000);
        },
    },
    components: {
        MessageContent,
    },
};
</script>