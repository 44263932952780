<template>
    <div class="p-3">
        <h5>Email Notifications</h5>

        <b-btn
            @click="setOption(option)"
            :key="option.value"
            v-for="option in options"
            variant="light"
            block
            class="my-2 text-left"
        >
            <font-awesome-icon
                v-if="option.selected"
                class="mr-1 text-primary"
                icon="fa-light fa-check"
            />

            {{ option.label }}
            <div class="small text-secondary">
                {{ option.description }}
            </div>
        </b-btn>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    created() {
        this.refresh();
    },
    data() {
        return {
            hotlist: false,
            options: null,
            hotlistOptions: null,
        };
    },
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        refresh() {
            this.api.post("/notification/options").then((response) => {
                if (response?.data?.notificationOptions) {
                    this.options = response?.data?.notificationOptions;
                }

                if (response?.data?.hotlist) {
                    this.hotlist = true;
                }

                if (response?.data?.hotlistDeliveryOptions) {
                    this.hotlistOptions =
                        response?.data?.hotlistDeliveryOptions;
                }
            });
        },
        setOption(option) {
            this.api
                .post("/notification/notifications", {
                    notifications: option.value,
                })
                .then(() => {
                    this.refresh();
                    this.$store.dispatch("onboarding/status");
                });
        },
        setHotlistOption(option) {
            this.api
                .post("/notification/hotlist", {
                    hotlistDelivery: option.value,
                })
                .then(() => {
                    this.refresh();
                });
        },
    },
};
</script>

