<template>
    <div id="conversation-actions">
        <div class="p-2 d-flex align-items-center justify-content-between">
            <div v-if="onboardingUser.creator">
                <div
                    v-if="totalEarnings"
                    class="d-flex justify-content-center align-items-center border rounded-pill px-2 py-1"
                >
                    <span class="small font-weight-bold px-1 mr-1">
                        <span>{{ $t("Conversation") }}</span>
                        <span v-if="$screen.md" class="ml-1">{{
                            $t("Earnings")
                        }}</span>
                    </span>
                    <b-badge v-if="totalEarnings" pill variant="light">
                        <font-awesome-icon
                            icon="fa-duotone fa-coin"
                            class="text-warning"
                        />
                        {{ totalEarnings }}
                    </b-badge>
                    <b-badge v-else variant="danger" pill>
                        <font-awesome-icon
                            icon="fa-duotone fa-triangle-exclamation"
                        />
                        {{ $t("Empty") }}
                    </b-badge>
                </div>
            </div>
            <div v-else>
                <wallet-balance />
            </div>
            <conversation-translation />
        </div>
        <div>
            <conversation-guaranteed-reply />
        </div>
        <div class="bg-white p-2 d-flex overflow-auto hide-scrollbars">
            <conversation-actions-call v-if="canCallUser" />
            <conversation-actions-media />
            <conversation-actions-template
                v-if="onboardingUser.templatesEnabled"
            />
            <conversation-actions-request
                v-if="showUncensoredCreatorActions && brand.enableRequest"
            />
            <conversation-actions-send-credits
                v-if="showUncensoredCreatorActions"
            />
        </div>

        <conversation-actions-input />
    </div>
</template>

<script>
import ConversationActionsCall from "@/components/conversation/ConversationActionsCall";
import ConversationActionsMedia from "@/components/conversation/ConversationActionsMedia";
import ConversationActionsTemplate from "@/components/conversation/ConversationActionsTemplate";
import ConversationActionsRequest from "@/components/conversation/ConversationActionsRequest";
import ConversationActionsSendCredits from "@/components/conversation/ConversationActionsSendCredits";
import ConversationActionsInput from "@/components/conversation/ConversationActionsInput";
import ConversationGuaranteedReply from "@/components/conversation/ConversationGuaranteedReply";
import ConversationTranslation from "@/components/conversation/ConversationTranslation";

import WalletBalance from "@/components/utility/WalletBalance";

import { mapGetters, mapState } from "vuex";

export default {
    computed: {
        ...mapGetters("profile", [
            "showUncensoredCreatorActions",
            "canCallUser",
        ]),
        ...mapState("brand", ["brand"]),
        ...mapState("profile", ["profile"]),
        ...mapState("user", ["premium"]),
        ...mapState("conversation", ["totalEarnings"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    components: {
        ConversationActionsCall,
        ConversationActionsInput,
        ConversationActionsMedia,
        ConversationActionsTemplate,
        ConversationActionsRequest,
        ConversationActionsSendCredits,
        ConversationGuaranteedReply,
        ConversationTranslation,
        WalletBalance,
    },
};
</script>