<template>
    <div>
        <div class="py-3">
            <b-alert variant="danger" show>
                <h3>
                    <font-awesome-icon
                        icon="fa-duotone fa-triangle-exclamation"
                    />
                    Email Complaint Detected
                </h3>
                <div>
                    We’ve been notified that one of our emails from your account
                    was marked as spam. This means we might not be able to send
                    you important messages anymore, even ones you actually want
                    to receive
                </div>
            </b-alert>

            <h5 class="mt-3">How to fix this?</h5>
            <div>
                Open your email inbox, go to the spam or junk folder, find the
                email that was marked as spam, and mark it as "Not Spam" or "Not
                Junk." This tells your email provider you still want to receive
                messages from us
            </div>

            <h5 class="mt-3">Limit emails to only essential ones</h5>
            <div>
                To receive only critical emails (like login or security
                updates), click on the "Account" tab in the top menu, scroll to
                the "Privacy" section, and choose "Notifications." Here, you can
                turn off other types of emails while keeping the essential ones
                active
            </div>

            <h5 class="mt-3">Unsubscribe from specific emails</h5>
            <div>
                Every email we send has an "Unsubscribe" link at the bottom.
                Clicking it will stop that type of email. You’ll also see an
                option to manage all your email preferences for added
                convenience
            </div>

            <b-row class="mt-4">
                <b-col cols="12" md="6" class="my-2">
                    <b-btn
                        variant="primary"
                        @click="closeModal"
                        pill
                        block
                        size="lg"
                    >
                        Ok, got it
                    </b-btn>
                </b-col>
                <b-col cols="12" md="6" class="my-2">
                    <b-btn
                        variant="secondary"
                        @click="maybeLater"
                        pill
                        block
                        size="lg"
                    >
                        Remind me later
                    </b-btn>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        currentOffer() {
            return this.$store.state.displayOffer?.currentOffer;
        },
    },
    methods: {
        closeModal() {
            this.$store.commit("interface/setDisplayOfferModalVisible", false);
        },
        maybeLater() {
            this.api.post("/offer/removeViewedOffer", {
                offer: this.currentOffer.offer,
            });

            this.$store.commit("interface/setDisplayOfferModalVisible", false);
        },
    },
};
</script>