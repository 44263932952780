<template>
    <div class="rounded p-3 bg-white text-left">
        <div class="d-flex align-items-center justify-content-between">
            <h5 class="mb-0">My Invite Link</h5>
        </div>

        <div v-if="url" class="mt-2">
            <b-input-group>
                <b-form-input :value="url" readonly />
                <b-input-group-append>
                    <b-btn v-if="copiedUrl" variant="success">
                        <font-awesome-icon icon="fa-light fa-check" />
                        Copied
                    </b-btn>
                    <b-btn v-else @click="copyUrl" variant="primary">
                        {{ $t("Copy") }}
                    </b-btn>
                </b-input-group-append>
            </b-input-group>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import copy from "clipboard-copy";

export default {
    computed: {
        ...mapState("affiliate", ["url", "copiedUrl"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        copyUrl() {
            copy(this.url);

            this.$store.commit("affiliate/setCopiedUrl", true);

            setTimeout(() => {
                this.$store.commit("affiliate/setCopiedUrl", false);
            }, 3000);
        },
    },
    components: {},
};
</script>