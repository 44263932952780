<template>
    <div>
        <div v-if="!loading">
            <h5>
                {{ translation.title }}
            </h5>
            <div>
                {{ translation.explainer }}
            </div>
            <b-btn
                @click="setPrimary(language.id)"
                variant="primary"
                pill
                block
                size="lg"
                class="mt-3"
            >
                {{ translation.accept }}
            </b-btn>
            <b-btn
                @click="setManual"
                variant="light"
                pill
                block
                size="lg"
                class="mt-3"
            >
                {{ translation.other }}
                <div class="small text-secondary">
                    {{ $t("Use another language") }}
                </div>
            </b-btn>
        </div>
        <div v-else>
            <generic-loading :label="$t('Detecting Language...')" />
        </div>
    </div>
</template>


<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            loading: false,
            translation: false,
            language: null,
        };
    },
    created() {
        this.refresh();
    },
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    methods: {
        setPrimary(id) {
            this.$store.dispatch("languageSelector/setPrimary", { id });
        },
        setManual() {
            this.$store.commit("languageSelector/setManual", true);
        },
        refresh() {
            this.loading = true;

            this.api
                .post("/language/detect", {
                    browserLanguage: navigator.language,
                })
                .then((response) => {
                    if (response.data.language) {
                        this.language = response.data.language;
                        this.translation = response.data.translation;
                    } else {
                        this.$store.commit("languageSelector/setManual", true);
                    }
                })
                .catch(() => {})
                .then(() => {
                    this.loading = false;
                });
        },
    },
};
</script>