<template>
    <generic-page>
        <div v-if="customer">
            <b-btn
                variant="link"
                size="lg"
                class="pl-0"
                to="/affiliate/customer/index"
            >
                <font-awesome-icon size="lg" icon="fa-light fa-chevron-left" />
                Customers
            </b-btn>

            <div v-if="highValue">
                <affiliate-customer-view-high-value :customer="customer" />
            </div>
            <div v-else>
                <affiliate-customer-view-default :customer="customer" />
            </div>
        </div>
        <div v-else>
            <generic-loading />
        </div>
    </generic-page>
</template>

<script>
import { mapState } from "vuex";

import AffiliateCustomerViewHighValue from "@/components/affiliate/customer/AffiliateCustomerViewHighValue";
import AffiliateCustomerViewDefault from "@/components/affiliate/customer/AffiliateCustomerViewDefault";

export default {
    data() {
        return {
            customer: null,
        };
    },
    created() {
        if (this.$route.params?.id) {
            this.refresh();
        }
    },
    computed: {
        ...mapState("affiliate", ["highValue"]),
    },
    methods: {
        refresh() {
            this.api
                .post("/affiliate/customer/get", {
                    userId: this.$route.params?.id,
                })
                .then((response) => {
                    if (response?.data?.customer) {
                        this.customer = response.data.customer;
                    }
                });
        },
    },
    components: {
        AffiliateCustomerViewDefault,
        AffiliateCustomerViewHighValue,
    },
};
</script>