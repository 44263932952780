<template>
    <div id="search-pagination">
        <div
            v-if="showPagination"
            class="d-flex align-items-center justify-content-between p-3"
        >
            <div>
                <b-btn
                    @click="previous"
                    variant="light"
                    size="lg"
                    block
                    pill
                    class="p-3 border-0 d-flex align-items-center"
                    :disabled="page === 0"
                >
                    <font-awesome-icon icon="fa-light fa-arrow-left" />
                    <div class="ml-2 d-none d-md-block">
                        {{ $t("Previous") }}
                    </div>
                </b-btn>
            </div>
            <div class="text-center text-secondary">Page {{ page + 1 }}</div>
            <div>
                <b-btn
                    @click="next"
                    variant="light"
                    size="lg"
                    block
                    pill
                    class="p-3 border-0 d-flex align-items-center"
                    :disabled="!probablyHasMoreSearchResults"
                >
                    <div class="mr-2 d-none d-md-block">
                        {{ $t("Next") }}
                    </div>
                    <font-awesome-icon icon="fa-light fa-arrow-right" />
                </b-btn>
            </div>
        </div>
        <div v-else class="d-flex justify-content-center py-4">
            <search-reset />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import SearchReset from "@/components/search/SearchReset";

export default {
    computed: {
        showPagination() {
            if (!this.probablyHasMoreSearchResults && this.page === 0) {
                return false;
            }

            return true;
        },
        ...mapState("search", ["page", "probablyHasMoreSearchResults"]),
    },
    methods: {
        resetScroll() {
            this.$scrollToTop();
        },
        next() {
            this.resetScroll();
            this.$store.dispatch("search/nextPage");
        },
        previous() {
            this.resetScroll();
            this.$store.dispatch("search/previousPage");
        },
    },
    components: {
        SearchReset,
    },
};
</script>
