<template>
    <div v-if="featuredUsers" class="bg-white mt-3">
        <h5 class="text-center p-3 mb-0">More users matching your filters</h5>

        <div
            @click="viewProfile(featuredUser)"
            :key="featuredUser.id"
            v-for="featuredUser in featuredUsers"
        >
            <user-standard-avatar
                :standardMap="featuredUser"
                :nonPrimaryPhotos="false"
                :hideBadges="true"
                :userBetweenStatus="false"
                class="border-light border-top"
                size="small"
            >
                {{ featuredUser.location }}
            </user-standard-avatar>
        </div>
    </div>
</template>


<script>
import { mapState } from "vuex";

import UserStandardAvatar from "@/components/user/UserStandardAvatar";

export default {
    computed: {
        ...mapState("search", ["featuredUsers"]),
    },
    methods: {
        viewProfile(standardMap) {
            this.$store.dispatch("profile/viewProfile", {
                userId: standardMap.id,
                profile: standardMap,
            });
        },
    },
    components: {
        UserStandardAvatar,
    },
};
</script>
