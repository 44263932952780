<template>
    <div>
        <div v-if="automatic">
            <language-selector-automatic />
        </div>
        <div v-else>
            <language-selector-manual />
        </div>
    </div>
</template>


<script>
import { mapState } from "vuex";

import LanguageSelectorAutomatic from "@/components/languageSelector/LanguageSelectorAutomatic";
import LanguageSelectorManual from "@/components/languageSelector/LanguageSelectorManual";

export default {
    mounted() {
        // reset upon remounting
        this.$store.commit("languageSelector/setManual", false);
    },
    computed: {
        automatic() {
            return !this.onboardingUser.primaryLanguage && !this.manual;
        },
        ...mapState("languageSelector", ["manual"]),
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
    },
    components: {
        LanguageSelectorManual,
        LanguageSelectorAutomatic,
    },
};
</script>
