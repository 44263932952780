import api from "@/service/api";

const state = () => ({
    hasPrimaryLanguage: false,
    manual: false,
})

const mutations = {
    setHasPrimaryLanguage(state, value) {
        state.hasPrimaryLanguage = value
    },
    setManual(state, value) {
        state.manual = value
    },
}

const actions = {
    setPrimary({ commit }, { id } = {}) {
        api.post(`/language/${id}/setPrimary`).then(() => {
            // to hook into event to know when its been set
            commit('setHasPrimaryLanguage', true)
        });
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}



