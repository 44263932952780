<template>
    <b-btn to="/earnings" class="bg-white" variant="light" block size="lg">
        <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
                <div class="mr-2">
                    <font-awesome-icon
                        class="small-avatar branded"
                        icon="fa-duotone fa-coin"
                        size="lg"
                    />
                </div>
                {{ $t("My Money") }}
            </div>
            <div class="small">
                {{ balanceLocal }}
                {{ onboardingUser.currency }}
            </div>
        </div>
    </b-btn>
</template>


<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),

        balanceLocal() {
            return this.onboardingUser?.wallet?.balanceLocal;
        },
    },
};
</script>