<template>
    <div class="message-content px-3 py-2">
        <div v-if="message.type === 'text'">
            <div v-if="message.translation">
                <div class="d-flex align-items-center">
                    <font-awesome-icon icon="fa-duotone fa-language" />
                    <preserve-content-spacing
                        class="ml-2"
                        :content="message.translation"
                    />
                </div>
            </div>
            <div v-else>
                <preserve-content-spacing :content="message.content" />
            </div>
        </div>
        <div v-if="message.type === 'sentCredit'">
            {{ $t("Sent money") }}
            <h5 class="mb-0">
                {{ message.content.local }}
            </h5>
            <coin-badge class="border" :amount="message.content.amount" />
        </div>
        <div v-if="message.type === 'call'">
            <font-awesome-icon icon="fa-duotone fa-phone" />
            {{ message.content.status }}
        </div>
        <div v-if="message.type === 'info'">
            <div>
                <font-awesome-icon icon="fa-duotone fa-info-circle" />
                {{ message.content }}
            </div>
        </div>
        <div v-if="message.type === 'photo'">
            <message-content-photo :message="message" />
        </div>
        <div v-if="message.type === 'video'">
            <video-player
                :muted="false"
                :controls="true"
                :autoplay="false"
                :mp4Url="message.content.mp4Url"
                :webmUrl="message.content.webmUrl"
            />
        </div>
        <div class="small d-flex align-items-center">
            <live-timestamp
                class="message-timestamp"
                :timestamp="message.created"
            />
            <span class="ml-1 text-primary" v-if="message.self && message.seen">
                <font-awesome-icon class="mr-1" icon="fa-duotone fa-eye" />
            </span>
        </div>
        <div v-if="message.refundable" class="text-success small">
            {{ $t("Eligible for refund") }}
        </div>
    </div>
</template>

<script>
import LiveTimestamp from "@/components/utility/LiveTimestamp";
import PreserveContentSpacing from "@/components/utility/PreserveContentSpacing";
import VideoPlayer from "@/components/utility/VideoPlayer";
import MessageContentPhoto from "@/components/conversation/MessageContentPhoto";

export default {
    components: {
        LiveTimestamp,
        PreserveContentSpacing,
        VideoPlayer,
        MessageContentPhoto,
    },
    props: ["message"],
};
</script>