<template>
    <div class="mt-2">
        <h5 class="m-0">
            {{ $t("Do you want to go private instead?") }}
        </h5>
        <div class="mt-3">
            {{
                $t(
                    "When your profile is set to private, no new people will be able to find your profile or message you. You can carry on with any conversations you already started."
                )
            }}
        </div>
        <div class="mt-3">
            {{
                $t(
                    "We'll send you emails to let you know when we have new users, and if you see someone you like you can just click on them and send them a message, so once private you'll have to make the first move."
                )
            }}
        </div>

        <div v-if="loading">
            <generic-loading />
        </div>
        <div v-else>
            <b-btn
                @click="savedDelete"
                variant="primary"
                block
                size="lg"
                pill
                class="mt-3"
            >
                {{ $t("Enable Private Mode") }}
            </b-btn>
            <b-btn
                @click="$store.dispatch('delete/saveFailed')"
                variant="secondary"
                block
                pill
                size="sm"
                class="mt-3"
            >
                {{ $t("No thanks, just delete") }}
            </b-btn>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
        };
    },
    methods: {
        async savedDelete() {
            this.loading = true;

            try {
                await this.api
                    .post("/setting/private", {
                        private: true,
                    })
                    .then(async () => {
                        await this.$store.dispatch("onboarding/status");
                        this.$store.dispatch("delete/saved");
                        this.$router.push("/account");
                    });
            } catch (e) {
                this.loading = false;
            }
        },
    },
};
</script>